<template>
    <el-dialog
        title="考试试卷"
        :visible.sync="examPaperDialog"
        :destroy-on-close="false"
        :append-to-body="true"
        width="60%"
        style="height: 90vh"
        v-loading="mainLoading"
        :element-loading-text="mainLoadingText"
        :close-on-click-modal="false">
        <el-row :gutter="16" class="table-container">
            <el-col :span="24">
                <el-form label-width="80px" :inline="true">
                    <el-form-item label="关键字">
                        <el-input v-model="queryParams.keyword" placeholder="查询关键字"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" icon="el-icon-search"  @click="refreshTable" round>查询</el-button>
                    </el-form-item>
                    <div style="float: right; padding: 0px 0">
                        <el-button-group>
                            <el-button size="mini" type="success" round icon="el-icon-plus" @click="toAdd">新增
                            </el-button>
                        </el-button-group>
                    </div>
                </el-form>
            </el-col>
            <el-col :span="24">
                <el-table
                    v-loading="tableLoading"
                    :data="tableData"
                    :show-header="true"
                    fit
                    style="width: 100%;"
                    ref="queryTable"
                    :indent="20"
                >
                    <el-table-column
                        label="#"
                        type="index"
                        width="50">
                    </el-table-column>
                    <el-table-column
                        prop="mstCode"
                        label="试卷编号" width="150">
                    </el-table-column>
                    <el-table-column
                        prop="mstName"
                        label="试卷名称">
                    </el-table-column>

                    <el-table-column label="操作" align="center" width="300" fixed="right" class-name="small-padding fixed-width">
                        <template slot-scope="{row}">
                            <el-button v-if="row.mstType===0 && row.deleteStatus===0" type="text" size="mini" icon="el-icon-edit" @click="toEdit(row.mstId)">修改</el-button>
                            <el-button v-if="row.mstType===0 && row.deleteStatus===0" type="text" size="mini"  icon="el-icon-delete" @click="toDelete(row.mstId, 1)">删除</el-button>
                            <el-button v-if="row.deleteStatus===0" type="text" size="mini"  icon="el-icon-refresh" @click="togenPaper(row.mstId)">重新生成</el-button>
                            <el-button v-if="row.deleteStatus===0" type="text" size="mini"  icon="el-icon-download" @click="downloadPaper(row.mstId)">试卷</el-button>
                            <el-button v-if="row.deleteStatus===0" type="text" size="mini"  icon="el-icon-download" @click="downloadAnwser(row.mstId)">答案</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>
        <span slot="footer" class="dialog-footer">
            <el-button @click="examPaperDialog = false">关 闭</el-button>
        </span>
        <el-dialog
            title="试卷信息"
            :visible.sync="editDialog"
            :append-to-body="true"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="30%">
            <el-form :model="info" :rules="ruleValidate" ref="editForm" label-width="120px" label-suffix=":">
                <el-form-item label="试卷标题" prop="mstName">
                    <el-input v-model="info.mstName"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
               <el-button @click="editDialog = false">关 闭</el-button>
                <el-button type="primary" @click="saveData('editForm')">保 存</el-button>
            </span>
        </el-dialog>
    </el-dialog>
</template>

<script>
export default {
    components: {
    },
    data() {
        return {
            mainLoading: false,
            mainLoadingText: '正在处理中，请稍等',
            examPaperDialog: false,
            tableLoading: false,
            queryParams: {
                ruleId: '',
                pageNumber: 0,
                pageSize: 100,
                totalRecord: 0,
                deleteStatus: 0
            },
            tableData: [],
            ruleId: '',
            info: {
                mstName: ''
            },
            ruleValidate: {
                mstName: [
                    { required: true, message: '请输入试卷标题', trigger: 'blur' }
                ],
            },
            editDialog: false,
        }
    },
    mounted() {
    },
    methods: {
        open: function (ruleId) {
            this.examPaperDialog = true;
            this.ruleId = ruleId;
            this.queryParams.ruleId = ruleId;
            this.refreshTable();
        },
        toAdd: function () {
            this.info = {
                ruleId: this.ruleId,
                mstName: ''
            }
            this.editDialog = true
        },
        initTable: function () {
            this.tableLoading = true;
            this.apis.postForm('course/examPaper/mst/query', this.queryParams)
                .then((res) => {
                    if (res.code === 1) {
                        this.tableData = res.data;
                        this.queryParams.totalRecord = res.count;
                        this.tableLoading = false;
                    } else {
                        this.tableLoading = false;
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                this.tableLoading = false;
                console.log(err)
            });
        },
        refreshTable: function () {
            this.queryParams.pageNumber = 0;
            this.initTable();
        },
        saveData: async function (formRef) {
            this.$refs[formRef].validate((valid) => {
                if (valid) {
                    let url = "course/examPaper/mst/save";
                    this.info.createBy = this.appCookies.getCookieUID();
                    this.info.createByname = this.appCookies.getCookieUNAME() ;
                    this.info.modifyBy = this.appCookies.getCookieUID();
                    this.info.modifyByname = this.appCookies.getCookieUNAME() ;
                    // 保存数据
                    this.apis.postJson(url, this.info)
                        .then((res) => {
                            if (res.code === 1) {
                                this.editDialog = false;
                                this.initTable();
                                // this.$message({
                                //     message: '保存成功',
                                //     showClose: true, offset: 200, type: 'success'
                                // });
                                this.genPaper(res.data)
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }
            })
        },
        toEdit: function (mstId) {
            this.apis.get('/course/examPaper/mst/getById?mstId=' + mstId)
                .then((res) => {
                    console.log(res);
                    if (res.code === 1) {
                        this.info = res.data;
                        this.editDialog = true;
                    } else {
                        this.info = {}
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        toDelete: function (mstId, deleteStatus) {
            let title = '确定要删除此条数据？';
            if (deleteStatus === 0) {
                title = '确定要恢复此条数据？';
            }
            this.$confirm(title, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    mstId: mstId,
                    deleteStatus: deleteStatus,
                    operUserId: this.appCookies.getCookieUID(),
                    operUserName: this.appCookies.getCookieUNAME()
                };
                this.apis.postForm('/course/examPaper/mst/delete', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.initTable();
                            this.$message({
                                message: '操作成功',
                                showClose: true, offset: 200, type: 'success'
                            });
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            }).catch(() => {
            });
        },
        togenPaper: function (mstId) {
            this.$confirm('确定要生成试卷？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.genPaper(mstId)
            }).catch(() => {
            });
        },
        genPaper: function (mstId) {
            this.mainLoading = true;
            this.mainLoadingText = "正在生成试卷，请稍等"
            let data = {
                ruleId: this.ruleId,
                mstId: mstId,
                operUserId: this.appCookies.getCookieUID(),
                operUserName: this.appCookies.getCookieUNAME()
            };
            this.apis.postForm('/course/examPaper/mst/genDtl', data)
                .then((res) => {
                    if (res.code === 1) {
                        this.$message({
                            message: '生成试卷成功',
                            showClose: true, offset: 200, type: 'success'
                        });
                    } else {
                        this.$message({
                            message: res.msg,
                            showClose: true, offset: 50, type: 'error'
                        });
                    }
                    this.mainLoading = false;
                }).catch(err => {
                this.mainLoading = false;
                console.log(err);
            });
        },
        downloadPaper: function (mstId) {
            let params = {
                mstId: mstId
            }
            this.mainLoading = true;
            this.mainLoadingText = '正在导出，请稍等';
            this.apis.axios()({
                method: 'post',
                url: 'course/examPaper/mst/exportPaper',
                data: this.$http.adornParams(params),
                'responseType': 'blob'
            }, false, 'json').then((res) => {
                if (res.type === 'application/json') {
                    this.$message({
                        message: '导出数据失败',
                        showClose: true,
                        offset: 200,
                        type: 'error'
                    });
                    this.mainLoading = false;
                    return false
                }
                let date = new Date();
                let year = date.getFullYear();
                let timestamp = date.valueOf();
                this.downFile(res, year.toString() + timestamp.toString() + '考试试卷.zip')
                this.mainLoading = false;
            }).catch(err => {
                this.mainLoading = false;
                console.log(err);
            });
        },
        downloadAnwser: function (mstId) {
            let params = {
                mstId: mstId
            }
            this.mainLoading = true;
            this.mainLoadingText = '正在导出，请稍等';
            this.apis.axios()({
                method: 'post',
                url: 'course/examPaper/mst/exportAnwser',
                data: this.$http.adornParams(params),
                'responseType': 'blob'
            }, false, 'json').then((res) => {
                if (res.type === 'application/json') {
                    this.$message({
                        message: '导出数据失败',
                        showClose: true,
                        offset: 200,
                        type: 'error'
                    });
                    this.mainLoading = false;
                    return false
                }
                let date = new Date();
                let year = date.getFullYear();
                let timestamp = date.valueOf();
                this.downFile(res, year.toString() + timestamp.toString() + '考试试卷答案.zip')
                this.mainLoading = false;
            }).catch(err => {
                this.mainLoading = false;
                console.log(err);
            });
        },
        /**
         * 文件流下载
         */
        downFile(content, fileName) {
            let blob = new Blob([content], {
                type: 'application/vnd.ms-excel'
            });
            let objectUrl = URL.createObjectURL(blob);
            let link = document.createElement('a');
            link.style.display = 'none';
            link.href = objectUrl;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        },
    }
}
</script>

<style scoped lang="scss">
.img-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 0;
    .img-box {
        width: 150px;
        height: 250px;
        padding: 20px 0;
        cursor: pointer;
    }
}
</style>
