<template>
    <div class="app-container">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <el-row :gutter="8" class="row-container">
            <!-- 工种分类 -->
            <el-col :span="5">
                <el-card class="fix-height-card" shadow="never" style="height:calc(100vh - 146px);">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>工种等级分类</span>
                    </div>
                    <GnvqStoreTree ref="gnvqTree" @callback="toQuery"></GnvqStoreTree>
                </el-card>
            </el-col>
            <!-- 列表主体 -->
            <el-col :span="19">
                <el-card class="box-card" shadow="never">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>试题列表</span>
                        <div style="float: right; padding: 0px 0">
                            <el-button-group>
                                <el-button type="primary" size="mini" round @click="batchToRelease(1)">启用</el-button>
                                <el-button type="primary" size="mini" @click="batchToRelease(0)">停用</el-button>
                                <el-button type="primary" size="mini" @click="batchToDelete">删除</el-button>
                                <el-button type="primary" size="mini" round @click="batchToCopy">复制</el-button>
                            </el-button-group>
                            <a  href="../../SHITIImportModelv1.xlsx" target="_blank" style="margin: 0 10px;">下载模板</a>
                            <el-button-group>
                                <el-button type="success" size="mini" round icon="el-icon-plus" @click="toAdd">新增</el-button>
                                <el-button class="filter-item"
                                    size="mini"
                                    v-show="queryParams.levelId || queryParams.baseCatId"
                                    type="success"
                                    round
                                    icon="el-icon-edit"
                                    @click="toMoveGroup">转移分组
                                </el-button>
                                <el-button type="success" size="mini" round icon="el-icon-plus" @click="toImport">导入</el-button>
                            </el-button-group>

                        </div>
                    </div>
                    <!-- 搜索查询 -->
                    <el-row class="query-container">
                        <el-col :span="24">
                            <el-form :inline="true">
                                <el-form-item label="">
                                    <el-input v-model="queryParams.keyword" placeholder="查询关键字" style="width:140px"></el-input>
                                </el-form-item>
                                <el-form-item label="">
                                    <el-select v-model="queryParams.useStatus" placeholder="请选择" style="width:100px">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="禁用" value="0"></el-option>
                                        <el-option label="可用" value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-select v-model="queryParams.examItemType" placeholder="请选择" style="width:100px">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="多选题" value="1"></el-option>
                                        <el-option label="单选题" value="2"></el-option>
                                        <el-option label="判断题" value="3"></el-option>
                                        <el-option label="论述题" value="4"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-select v-model="queryParams.deleteStatus" placeholder="请选择" style="width:100px">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="正常" value="0"></el-option>
                                        <el-option label="已删除" value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-select v-model="queryParams.difficultyGrade" placeholder="请选择" style="width:100px">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option v-for="item in gradeList" :key="item.id" :value="item.id" :label="item.name" />
                                    </el-select>
                                </el-form-item>
                                <el-form-item v-show="queryParams.levelId || queryParams.baseCatId" style="width: 100px">
                                    <GroupEditorSelect ref="groupSelect"
                                        apiPrefix="/course/examItem/group"
                                        :editable="false"
                                        :groupId.sync="queryParams.groupId"
                                        :baseCatId="queryParams.baseCatId"
                                        :gnvqId="queryParams.gnvqId"
                                        :levelId="queryParams.levelId">
                                    </GroupEditorSelect>
                                </el-form-item>
                                <el-form-item v-show="this.queryParams.levelId || this.queryParams.baseCatId" style="width: 150px">
                                    <SelectTree :tree-data="queryPointList" :selectId.sync="queryParams.pointId"
                                                :treeProps="treeProps" :nodeKey="'pointId'"
                                                :onlySelectLeaf="false"></SelectTree>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                                </el-form-item>
                            </el-form>
                        </el-col>
                    </el-row>
                    <!-- 列表内容 -->
                    <el-row class="table-container">
                        <el-col>
                            <el-table
                                v-loading="tableLoading"
                                :data="tableData"
                                fit
                                stripe
                                highlight-current-el-row
                                style="width: 100%;"
                                height="calc(100vh - 300px)"
                                ref="queryTable"
                                :indent="20"
                                @selection-change="handleSelectionChange"
                            >
                                <el-table-column
                                    type="selection"
                                    width="50">
                                </el-table-column>
                                <el-table-column label="试题名称" prop="examItemName" min-width="300px" align="left" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="试题分类" min-width="100px" :show-overflow-tooltip="true">
                                    <template slot-scope="{row}">
                                        <el-tag :type="row.examItemCat | examItemCatColorFilter">
                                            {{ row.examItemCat  | examItemCatFilter}}
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="试题类型" prop="examItemType" min-width="80px" :show-overflow-tooltip="true">
                                    <template slot-scope="{row}">
                                        <el-tag :type="row.examItemType | examItemTypeColorFilter">
                                            {{ row.examItemType  | examItemTypeFilter}}
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="基础题类别" prop="baseCatName" min-width="100px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="工种" prop="gnvqName" min-width="140px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="等级" prop="levelName" min-width="80px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="分组" prop="groupName" min-width="100px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="排序号" prop="orderNum" min-width="80px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="可用状态" prop="useStatus" min-width="100px" align="center" :show-overflow-tooltip="true">
                                    <template slot-scope="{row}">
                                        <el-switch
                                            v-model="row.useStatus"
                                            :active-value="1"
                                            :inactive-value="0"
                                            active-color="#2d8cf0"
                                            inactive-color="#efefef"
                                            @change="toLock(row)">
                                        </el-switch>
                                    </template>
                                </el-table-column>
                                <el-table-column label="状态" min-width="100px" align="center">
                                    <template slot-scope="{row}">
                                        <el-tag :type="row.deleteStatus | colorFilter">
                                            {{ row.deleteStatus  | deleteStatusFilter}}
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作人" prop="modifyByname" min-width="100px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="操作时间" prop="modifyTime" min-width="180px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="操作" align="center" width="140" fixed="right" class-name="small-padding fixed-width">
                                    <template slot-scope="{row}">
                                        <el-button v-if="row.deleteStatus===0" type="text" size="mini" icon="el-icon-edit" @click="toEdit(row.examItemId)">编辑</el-button>
                                        <el-button v-if="row.deleteStatus===0" type="text" size="mini"  icon="el-icon-delete" @click="toDelete(row.examItemId, 1)">删除</el-button>
                                        <el-button v-if="row.deleteStatus===1" type="text" size="mini"  icon="el-icon-refresh-right" @click="toDelete(row.examItemId, 0)">恢复</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>

                            <!-- 分页组件 -->
                            <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                                        :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                                        @pagination="initTable"/>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
        <el-dialog
            title="转移分组"
            :visible.sync="moveGroupDialog"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="700px">
            <el-form :model="moveGroupInfo" ref="moveGroupForm" label-width="140px" label-suffix=":">
                <el-row>
                    <el-col :span="20" :offset="2">
                        <el-form-item label="分组" prop="moveGroupInfo.groupId">
                            <GroupEditorSelect
                                ref="moveGroupEditor"
                                apiPrefix="/course/examItem/group"
                                :editable="false"
                                :groupId.sync="moveGroupInfo.groupId"
                                :baseCatId="queryParams.baseCatId"
                                :gnvqId="queryParams.gnvqId"
                                :levelId="queryParams.levelId">
                            </GroupEditorSelect>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="moveGroupDialog = false">关 闭</el-button>
                <el-button type="primary" @click="saveGroup()">保存</el-button>
            </span>
        </el-dialog>
        <!-- 【新增】侧边栏 -->
        <el-drawer
            title="试题管理"
            :visible.sync="editDrawer"
            :modal-append-to-body="true"
            :wrapperClosable="false"
            :destroy-on-close="true"
            direction="rtl"
            custom-class="demo-drawer"
            ref="editDrawer"
            size="80%"
        >
            <div class="demo-drawer__content">
                <el-form :model="info" :rules="ruleValidate" ref="editForm" label-width="100px" label-suffix=":">
                    <el-row :gutter="32">
                        <el-col>
                            <el-form-item label="试题名称" prop="examItemName">
                                <el-input v-model="info.examItemName" type="textarea" :rows="2"  placeholder="请输入试题名称"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col>
                            <el-form-item label="试题分类" prop="examItemCat" >
                                <el-radio-group v-model="info.examItemCat" @change="changeItemCat">
                                    <el-radio :label="1">基础课程题</el-radio>
                                    <el-radio :label="2">专业课程题</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32" v-show="info.examItemCat==1">
                        <el-col>
                            <el-form-item label="基础题类别" prop="baseCatId">
                                <el-select v-model="info.baseCatId" @change="onBaseCatIdChange">
                                    <el-option value="">请选择</el-option>
                                    <el-option v-for="item in baseCatList" :value="item.dictId" :label="item.dictName"  :key="item.dictId"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32" v-show="info.examItemCat==2">
                        <el-col :span="16">
                            <el-form-item label="工种等级" prop="categoryId">
                                <GnvqSelect ref="gnvqSelect" :gnvqCatId.sync="info.gnvqCatId" :gnvqId.sync="info.gnvqId"
                                            :levelId.sync="info.levelId"  @callback="levelChange"></GnvqSelect>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row v-show="info.levelId || info.baseCatId">
                        <el-col :span="16">
                            <el-form-item label="分组">
                                <GroupEditorSelect
                                    ref="groupEditor"
                                    apiPrefix="/course/examItem/group"
                                    :editable="true"
                                    :groupId.sync="info.groupId"
                                    :baseCatId="info.baseCatId"
                                    :gnvqId="info.gnvqId"
                                    :levelId="info.levelId">
                                </GroupEditorSelect>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="知识点" prop="pointId">
                                <SelectTree :tree-data="pointList" :selectId.sync="info.pointId"
                                            :treeProps="treeProps" :nodeKey="'pointId'"
                                            :onlySelectLeaf="true"></SelectTree>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="难度等级" prop="difficultyGrade">
                                <el-select v-model="info.difficultyGrade" placeholder="请选择">
                                    <el-option label="请选择" value=""></el-option>
                                    <el-option v-for="item in gradeList" :key="item.id" :value="item.id" :label="item.name" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col>
                            <el-form-item label="试题类型" prop="examItemType" >
                                <el-radio-group v-model="info.examItemType" @change="changeItemType">
                                    <el-radio :label="1">多选题</el-radio>
                                    <el-radio :label="2">单选题</el-radio>
                                    <el-radio :label="3">判断题</el-radio>
                                    <el-radio :label="4">论述题</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col>
                            <el-form-item label="试题图片">
                                <SingleUploadImg @callback="handleUploadImg" :imageUrl="info.examImageUrl"
                                                 :width="200" :height="100"></SingleUploadImg>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-form-item label="试题选项" v-show="info.examItemType==1 || info.examItemType==2">
                        <el-row :gutter="16">
                            <el-col :span="3" :offset="2">
                                <span>编号(A/B/C/D)</span>
                            </el-col>
                            <el-col :span="3">
                                <span>选项值(1/2/3/4)</span>
                            </el-col>
                            <el-col :span="10">
                                <span>选项标签</span>
                            </el-col>
                            <el-col :span="3">
                                <span>设置为正确答案</span>
                            </el-col>
                        </el-row>
                            <el-row :gutter="16" v-for="(item, index) in info.options" :key="index" style="margin: 5px 0">
                                <el-col :span="2" style="text-align: right">
                                    <span>选项{{index+1}}:</span>
                                </el-col>
                                <el-col :span="3">
                                    <el-input type="text" v-model="item.optionCode" placeholder="请输入选项编号"></el-input>
                                </el-col>
                                <el-col :span="3">
                                    <el-input type="text" v-model="item.optionValue" placeholder="请输入选项值"></el-input>
                                </el-col>
                                <el-col :span="10">
                                    <div style="display: flex">
                                        <el-input type="text" v-model="item.optionLable" placeholder="请输入选项描述"></el-input>
                                        <SingleUploadImg @callback="optionUploadImg" :index="index" :imageUrl="item.optionImageUrl"
                                                         :width="100" :height="30"></SingleUploadImg>
                                    </div>
                                </el-col>
                                <el-col :span="3">
                                    <el-checkbox v-model="item.trueFlag">是</el-checkbox>
                                </el-col>
                                <el-col :span="3">
                                    <el-button @click="optionsRemove(index)">删除</el-button>
                                </el-col>
                            </el-row>
                            <el-row :gutter="16" >
                                <el-col :span="16" :offset="2">
                                    <el-button plain @click="optionsAdd" icon="el-icon-plus" style="width:100%">添加</el-button>
                                </el-col>
                            </el-row>
                    </el-form-item>
                    <el-row :gutter="32" v-show="info.examItemType==3">
                        <el-col>
                            <el-form-item label="试题答案" prop="examItemAnswer">
                                <el-radio-group v-model="info.examItemAnswer">
                                    <el-radio label="Y">正确</el-radio>
                                    <el-radio label="N">错误</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32">
                        <el-col>
                            <el-form-item label="答案解释" prop="answerDesc">
<!--                                <el-input type="textarea" v-model="info.answerDesc" :rows="2" placeholder="请输入答案解释" />-->
                                <editor ref="editor" :height="300"></editor>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col>
                            <el-form-item label="序号" prop="orderNum">
                            <span slot="label">
                                序号：
                                <el-tooltip  content="显示的顺序，不控制可输入0" effect="light" placement="top">
                                    <i class="el-icon-question" style="color:red"></i>
                                </el-tooltip>
                            </span>
                                <el-input v-model="info.orderNum"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <div class="demo-drawer__footer">
                <el-button @click="editDrawer = false">关闭</el-button>
                <el-button  type="primary" v-show="!info.examItemId" @click="saveContinueeData('editForm')">保存继续</el-button>
                <el-button  type="primary" @click="saveData('editForm')">保存</el-button>
            </div>
        </el-drawer>
        <!-- 【导入】侧边栏 -->
        <el-drawer
            title="试题导入"
            :visible.sync="importDrawer"
            :modal-append-to-body="true"
            :wrapperClosable="false"
            :destroy-on-close="true"
            direction="rtl"
            custom-class="demo-drawer"
            ref="importDrawer"
            size="60%"
        >
            <div class="demo-drawer__content">
                <el-form :model="info" :rules="ruleValidate" ref="importForm" label-width="100px" label-suffix=":">
                    <el-row>
                        <el-col>
                            <el-form-item label="试题分类" prop="examItemCat" >
                                <el-radio-group v-model="info.examItemCat" @change="changeItemCat">
                                    <el-radio :label="1">基础课程题</el-radio>
                                    <el-radio :label="2">专业课程题</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32" v-show="info.examItemCat==1">
                        <el-col :span="8">
                            <el-form-item label="基础题类别" prop="baseCatId">
                                <el-select filterable v-model="info.baseCatId" @change="getPointList()">
                                    <el-option value="">请选择</el-option>
                                    <el-option v-for="item in baseCatList" :value="item.dictId" :label="item.dictName"  :key="item.dictId"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32" v-show="info.examItemCat==2">
                        <el-col :span="16">
                            <el-form-item label="工种等级" prop="categoryId">
                                <GnvqSelect ref="gnvqSelect" :gnvqCatId.sync="info.gnvqCatId" :gnvqId.sync="info.gnvqId"
                                            :levelId.sync="info.levelId"  @callback="levelChange"></GnvqSelect>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="知识点" prop="pointId">
                                <SelectTree :tree-data="pointList" :selectId.sync="info.pointId"
                                            :treeProps="treeProps" :nodeKey="'pointId'"
                                            :onlySelectLeaf="true"></SelectTree>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row v-show="info.levelId || info.baseCatId">
                        <el-col :span="16">
                            <el-form-item label="分组">
                                <GroupEditorSelect
                                    ref="importGroupEditor"
                                    apiPrefix="/course/examItem/group"
                                    :editable="true"
                                    :groupId.sync="info.groupId"
                                    :baseCatId="info.baseCatId"
                                    :gnvqId="info.gnvqId"
                                    :levelId="info.levelId">
                                </GroupEditorSelect>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col>
                            <el-form-item label="上传文件">
                                <el-upload
                                    class="upload-demo"
                                    accept=".xls,.xlsx"
                                    ref="upload"
                                    :action="getUploadUrl()"
                                    :limit="1"
                                    :before-upload="beforeUpload"
                                    :on-change="fileChange"
                                    :file-list="fileList"
                                    :auto-upload="false">
                                    <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                                    <div slot="tip" class="el-upload__tip">只能上传.xls,.xlsx文件</div>
                                </el-upload>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <div class="demo-drawer__footer">
                <el-button @click="importDrawer = false">取消</el-button>
                <el-button  type="primary" @click="addImport('importForm')">确定导入</el-button>
            </div>
        </el-drawer>
        <el-drawer
            title="试题复制-请选择要复制到的工种等级"
            :visible.sync="copyDrawer"
            :modal-append-to-body="true"
            :wrapperClosable="false"
            :destroy-on-close="true"
            direction="rtl"
            custom-class="demo-drawer"
            ref="copyDrawer"
            size="60%"
        >
            <div class="demo-drawer__content">
                <el-form :model="copyInfo" :rules="ruleValidate" ref="copyForm" label-width="100px" label-suffix=":">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="试题分类" prop="examItemCat" >
                                <el-radio-group v-model="copyInfo.examItemCat" @change="changeItemCat2">
                                    <el-radio :label="1">基础课程题</el-radio>
                                    <el-radio :label="2">专业课程题</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32" v-show="copyInfo.examItemCat==1">
                        <el-col :span="12">
                            <el-form-item label="基础题类别" prop="baseCatId">
                                <el-select v-model="copyInfo.baseCatId" @change="getPointList2()">
                                    <el-option value="">请选择</el-option>
                                    <el-option v-for="item in baseCatList" :value="item.dictId" :label="item.dictName"  :key="item.dictId"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32" v-show="copyInfo.examItemCat==2">
                        <el-col :span="16">
                            <el-form-item label="工种等级" prop="categoryId">
                                <GnvqSelect ref="gnvqSelect" :gnvqCatId.sync="copyInfo.gnvqCatId" :gnvqId.sync="copyInfo.gnvqId"
                                            :levelId.sync="copyInfo.levelId"  @callback="levelChange2"></GnvqSelect>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="知识点" prop="pointId">
                                <SelectTree :tree-data="pointList2" :selectId.sync="copyInfo.pointId"
                                            :treeProps="treeProps" :nodeKey="'pointId'"
                                            :onlySelectLeaf="true"></SelectTree>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <div class="demo-drawer__footer">
                <el-button @click="copyDrawer = false">取消</el-button>
                <el-button  type="primary" @click="copyConfirm('copyForm')">确定复制</el-button>
            </div>
        </el-drawer>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/Breadcrumb';
    import Pagination from '@/components/Pagination';
    import GnvqStoreTree from "@v/components/GnvqStoreTree";
    import GnvqSelect from "@v/components/GnvqSelect";
    import SingleUploadImg from "@c/upload/SingleUploadImg";
    import GroupEditorSelect from "@v/components/GroupEditorSelect";
    import SelectTree from "@/components/tree/SelectTree";
    import editor from "@c/editor/editor.vue";
    export default {
        components: {
            Pagination,
            Breadcrumb,
            GnvqStoreTree,
            GnvqSelect,
            SingleUploadImg,
            GroupEditorSelect,
            SelectTree,
            editor,
        },
        filters: {
            examItemCatFilter(status) {
                const statusMap = {
                    1: '基础课',
                    2: '专业课',
                };
                return statusMap[status]
            },
            examItemCatColorFilter(status) {
                const statusMap = {
                    1: 'success',
                    2: '',
                };
                return statusMap[status]
            },
            examItemTypeFilter(status) {
                const statusMap = {
                    1: '多选题',
                    2: '单选题',
                    3: '判断题',
                    4: '论述题',
                };
                return statusMap[status]
            },
            examItemTypeColorFilter(status) {
                const statusMap = {
                    1: '',
                    2: 'success',
                    3: 'warning',
                    4: 'info',
                };
                return statusMap[status]
            },
            deleteStatusFilter(status) {
                const statusMap = {
                    0: '正常',
                    1: '已删除',
                };
                return statusMap[status]
            },
            colorFilter(status) {
                const statusMap = {
                    0: 'success',
                    1: 'danger',
                };
                return statusMap[status]
            },
        },
        data () {
            const validateNumber = (rule, value, callback) => {
                if (!Number.isInteger(+value)) {
                    callback(new Error('请输入数值'));
                } else {
                    callback();
                }
            };
            const validateBaseCat = (rule, value, callback) => {
                if (this.info.examItemCat === 1 && !this.info.baseCatId) {
                    return callback(new Error('请选择基础题类别'));
                } else {
                    callback();
                }
            };
            const validateAnswer = (rule, value, callback) => {
                // console.log(value)
                if (this.info.examItemType === 3 && !value) {
                    return callback(new Error('请选择试题答案'));
                } else {
                    callback();
                }
            };
            return {
                crumbData: [
                    {
                        path: '',
                        name: '首页'
                    },
                    {
                        path: '',
                        name: '试题库管理'
                    }
                ],
                //列表查询数据
                queryParams: {
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    typeCode: '',
                    keyword: '',
                    deleteStatus: '0',
                    gnvqCatId: '',
                    gnvqId: '',
                    levelId: '',
                    pointId: '',
                    examItemCat: '',
                    baseCatId: '',
                    difficultyGrade: '',
                    groupId: '',
                    orgId: this.appCookies.getCookieOID()
                },
                gradeList: [
                    { id: 1, name: '等级1' },
                    { id: 2, name: '等级2' },
                    { id: 3, name: '等级3' },
                    { id: 4, name: '等级4' },
                    { id: 5, name: '等级5' },
                    { id: 6, name: '等级6' },
                    { id: 7, name: '等级7' },
                    { id: 8, name: '等级8' },
                    { id: 9, name: '等级9' },
                    { id: 10, name: '等级10' }
                ],
                //列表数据
                tableData: [],
                tableLoading: false,
                selectdata: [],
                moveGroupDialog: false,
                moveGroupInfo: {
                    groupId: 0,
                },
                //编辑、新增表单
                editDrawer: false,
                info: {
                    orderNum: 0
                },
                teacherList: [],
                teacherFinding: false,
                //表单验证规则
                ruleValidate: {
                    examItemName: [
                        { required: true, message: '请输入试题名称', trigger: 'blur' }
                    ],
                    examItemCat: [
                        { required: true, type: 'number', message: '请选择试题分类', trigger: 'change' }
                    ],
                    baseCatId: [
                        { required: true, validator: validateBaseCat, trigger: 'change' }
                    ],
                    difficultyGrade: [
                        { required: true, type: 'number', message: '请选择难度等级', trigger: 'change' }
                    ],
                    examItemType: [
                        { required: true, type: 'number', message: '请选择试题类型', trigger: 'change' }
                    ],
                    examItemAnswer: [
                        { required: true, validator: validateAnswer, trigger: 'change' }
                    ],
                    orderNum: [
                        { validator: validateNumber, trigger: 'blur' }
                    ]
                },
                baseCatList: [],
                pointList: [
                    // {
                    //     pointId: '0',
                    //     pointName: 'ooooo'
                    // }
                ],
                //知识点下拉菜单
                treeProps: {
                    value: 'pointId',
                    label: 'pointName',
                    children: 'children'
                },
                //导入
                importDrawer: false,
                file: null,
                fileList: [],
                //复制
                copyDrawer: false,
                copyInfo: {},
                pointList2: [
                    // { pointId: '0', pointName: '请选择' }
                ],
                queryPointList: [{ pointId: '', pointName: '请选择知识点' }],
            };
        },
        mounted () {
            this.getDictList();
            this.initTable();
        },
        computed: {
        },
        methods: {
            //复制
            changeItemCat2: function (val) {
                this.copyInfo.baseCatId = '';
                this.copyInfo.gnvqCatId = '';
                this.copyInfo.gnvqId = '';
                this.copyInfo.levelId = '';
                this.copyInfo.pointId = '0';
            },
            getQueryPointList: function () {
                this.queryPointList = [];
                if (this.queryParams.levelId || this.queryParams.baseCatId) {
                    let data = {
                        levelId: this.queryParams.levelId,
                        baseCatId: this.queryParams.baseCatId,
                    };
                    this.apis.get('gnvq/point/getList', data)
                        .then((res) => {
                            if (res.code === 1) {
                                this.queryPointList = res.data || []
                                // this.queryPointList.splice(0, 0, { pointId: '', pointName: '请选择知识点' });
                            } else {
                                console.log('发生错误:' + res.msg);
                            }
                        }).catch((err) => {
                        console.log(err)
                    });
                }
            },
            getPointList2: function () {
                let data = {
                    levelId: this.copyInfo.levelId,
                    baseCatId: this.copyInfo.baseCatId,
                };
                this.apis.get('gnvq/point/getList', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.pointList2 = res.data || []
                            // this.pointList2.splice(0, 0, { pointId: '0', pointName: '请选择' });
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            levelChange2: function () {
                this.getPointList2();
            },
            copyConfirm: async function (formRef) {
                this.$refs[formRef].validate((valid) => {
                    if (valid) {
                        if (this.selectdata.length === 0) {
                            this.$message({
                                message: '您未选择任何记录，请进行选择',
                                showClose: true, offset: 200, type: 'success'
                            });
                            return;
                        }
                        let examItemIds = [];
                        this.selectdata.forEach(function (item) {
                            examItemIds.push(item.examItemId)
                        });

                        this.copyInfo.examItemIds = examItemIds;
                        this.copyInfo.orgId = this.appCookies.getCookieOID();
                        this.copyInfo.createBy = this.appCookies.getCookieUID();
                        this.copyInfo.createByname = this.appCookies.getCookieUNAME() ;
                        this.copyInfo.modifyBy = this.appCookies.getCookieUID();
                        this.copyInfo.modifyByname = this.appCookies.getCookieUNAME() ;

                        // 保存数据
                        this.apis.postJson('course/examItem/batchCopy', this.copyInfo)
                            .then((res) => {
                                if (res.code === 1) {
                                    this.$message({
                                        message: '操作成功',
                                        showClose: true, offset: 200, type: 'success'
                                    });
                                    this.copyDrawer = false;
                                    this.initTable();
                                    // this.$refs.selectTable.getSelection()
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        showClose: true, offset: 50, type: 'error'
                                    });
                                }
                            }).catch(err => {
                            console.log(err);
                        });
                    }
                })
            },
            //启用、停用、删除
            handleSelectionChange(val) {
                this.selectdata = val;
            },
            batchToRelease: function (useStatus) {
                if (this.selectdata.length === 0) {
                    this.$message({
                        message: '您未选择任何记录，请进行选择',
                        showClose: true, offset: 200, type: 'success'
                    });
                    return;
                }
                let examItemIds = [];
                this.selectdata.forEach(function (item) {
                    examItemIds.push(item.examItemId)
                });
                let data = {
                    examItemIds: examItemIds,
                    useStatus: useStatus,
                    modifyBy: this.appCookies.getCookieUID(),
                    modifyByname: this.appCookies.getCookieUNAME()
                };
                this.apis.postJson('course/examItem/batchUpdUseStatus', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: '操作成功',
                                showClose: true, offset: 200, type: 'success'
                            });
                            this.initTable();
                            // this.$refs.selectTable.getSelection()
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            batchToDelete: function () {
                if (this.selectdata.length === 0) {
                    this.$message({
                        message: '您未选择任何记录，请进行选择',
                        showClose: true, offset: 200, type: 'success'
                    });
                    return;
                }
                let examItemIds = [];
                this.selectdata.forEach(function (item) {
                    examItemIds.push(item.examItemId)
                });

                let title = '确定要删除此条数据？';
                this.$confirm(title, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let data = {
                        examItemIds: examItemIds,
                        modifyBy: this.appCookies.getCookieUID(),
                        modifyByname: this.appCookies.getCookieUNAME()
                    };
                    this.apis.postJson('course/examItem/batchDelete', data)
                        .then((res) => {
                            if (res.code === 1) {
                                this.initTable();
                                this.$message({
                                    message: '操作成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }).catch(() => {
                });
            },
            batchToCopy: function () {
                if (this.selectdata.length === 0) {
                    this.$message({
                        message: '您未选择任何记录，请进行选择',
                        showClose: true, offset: 200, type: 'success'
                    });
                    return;
                }
                this.copyInfo = {
                    gnvqCatId: this.queryParams.gnvqCatId,
                    gnvqId: this.queryParams.gnvqId,
                    levelId: this.queryParams.levelId,
                    examItemCat: this.queryParams.examItemCat,
                    baseCatId: this.queryParams.baseCatId,
                    pointId: '0',
                };
                this.copyDrawer = true;
            },
            getDictList: function () {
                this.apis.get('system/dict/getList?typeCode=0004')
                    .then((res) => {
                        if (res.code === 1) {
                            this.baseCatList = res.data || []
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            //获取列表
            initTable: function () {
                this.tableLoading = true;
                this.apis.postForm('course/examItem/query', this.queryParams)
                    .then((res) => {
                        if (res.code === 1) {
                            this.tableData = res.data;
                            this.queryParams.totalRecord = res.count;
                            console.log(res);
                            this.tableLoading = false;
                        } else {
                            this.tableLoading = false;
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    this.tableLoading = false;
                    console.log(err)
                });
            },
            refreshTable: function () {
                this.queryParams.pageNumber = 0;
                this.initTable();
            },
            clearQuery: function () {
                this.queryParams.gnvqCatId = '';
                this.queryParams.gnvqId = '';
                this.queryParams.levelId = '';
                this.queryParams.examItemCat = '';
                this.queryParams.baseCatId = '';
                this.queryParams.pointId = '';
                this.queryParams.groupId = '';
            },
            toQuery: function (examItemCat, data) {
                this.clearQuery();
                this.queryParams.examItemCat = examItemCat;
                if (examItemCat === 1) {
                    this.queryParams.baseCatId = data.dictId;
                    this.getQueryPointList();
                } else {
                    if (data.type === 'CAT') {
                        this.queryParams.gnvqCatId = data.id;
                    } else if (data.type === 'INFO') {
                        this.queryParams.gnvqCatId = data.categoryId;
                        this.queryParams.gnvqId = data.id;
                    } else {
                        this.queryParams.gnvqCatId = data.categoryId;
                        this.queryParams.gnvqId = data.parentId;
                        this.queryParams.levelId = data.id;
                        this.getQueryPointList();
                    }
                }
                this.refreshTable();
            },
            toLock: function (row) {
                //console.log(el-row)
                let data = {
                    examItemId: row.examItemId,
                    useStatus: row.useStatus,
                    operUserId: this.appCookies.getCookieUID(),
                    operUserName: this.appCookies.getCookieUNAME()
                };
                this.apis.postForm('course/examItem/updUseStatus', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: '操作成功',
                                showClose: true, offset: 200, type: 'success'
                            });
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            //转移分组
            toMoveGroup: function () {
                if (this.selectdata.length === 0) {
                    this.$message({
                        message: '您未选择任何记录，请进行选择',
                        showClose: true, offset: 200, type: 'success'
                    });
                    return;
                }
                this.moveGroupDialog=true;
                this.moveGroupInfo.groupId = this.queryParams.groupId;
            },
            saveGroup: function () {
                let examItemIds = [];
                this.selectdata.forEach(function (item) {
                    examItemIds.push(item.examItemId)
                });
                let data = {
                    "examItemIds": examItemIds,
                    "toGroupId": this.moveGroupInfo.groupId,
                }
                // 保存数据
                this.apis.postJson('/course/examItem/batchUpdGroup', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: '保存成功',
                                showClose: true, offset: 200, type: 'success'
                            });
                            this.moveGroupDialog = false;
                            this.initTable();
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            toImport: function () {
                this.info = {
                    gnvqCatId: this.queryParams.gnvqCatId,
                    gnvqId: this.queryParams.gnvqId,
                    levelId: this.queryParams.levelId,
                    examItemCat: this.queryParams.examItemCat,
                    baseCatId: this.queryParams.baseCatId,
                    pointId: '0',
                };
                this.file = null;
                this.importDrawer = true;
            },
            //上传文件
            beforeUpload(file) {
                //文件类型
                const isType = file.type === 'application/vnd.ms-excel'
                const isTypeComputer = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                const fileType = isType || isTypeComputer
                if (!fileType) {
                    this.$message.error('上传文件只能是xls/xlsx格式！')
                }
                this.file = file;
                this.info.file = file;
                return fileType;
            },
            getUploadUrl: function () {
                return process.env.VUE_APP_API_HOST + 'system/file/uploadFile';
            },
            fileChange(file, fileList) {
                console.log(file)
                this.file = file.raw
            },
            //确定导入
            addImport: async function (formRef) {
                this.$refs[formRef].validate((valid) => {
                    if (valid) {

                        let formData = new FormData();
                        formData.append('file', this.file);

                        let url = "course/examItem/import";
                        this.info.orgId = this.appCookies.getCookieOID();
                        this.info.createBy = this.appCookies.getCookieUID();
                        this.info.createByname = this.appCookies.getCookieUNAME() ;
                        this.info.modifyBy = this.appCookies.getCookieUID();
                        this.info.modifyByname = this.appCookies.getCookieUNAME() ;

                        formData.append("info", new Blob([JSON.stringify(this.info)], { type: "application/json" }));
                        console.log(formData)
                        // 保存数据
                        this.apis.postFile(url, formData)
                            .then((res) => {
                                if (res.code === 1) {
                                    this.importDrawer = false;
                                    this.$message({
                                        message: '导入成功，共导入' + res.data.rightCount.toString() + '条记录；已存在记录' + res.data.wrongCount.toString() + '条',
                                        showClose: true, offset: 200, type: 'success'
                                    });
                                    this.initTable();
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        showClose: true, offset: 50, type: 'error'
                                    });
                                }
                            }).catch(err => {
                            console.log(err);
                        });
                    }
                })
            },
            toAdd: function () {
                this.info = {
                    orderNum: 0,
                    gnvqCatId: this.queryParams.gnvqCatId,
                    gnvqId: this.queryParams.gnvqId,
                    levelId: this.queryParams.levelId,
                    examItemCat: this.queryParams.examItemCat,
                    baseCatId: this.queryParams.baseCatId,
                    pointId: 0,
                    options: []
                };
                this.$nextTick(function () {
                    this.$refs.editor.setHtml("");
                });
                this.$nextTick(function() {
                    // this.$refs.gnvqSelect.initData(this.info.gnvqCatId, this.info.gnvqId, this.info.levelId);
                });
                //console.log(this.info);
                this.editDrawer = true;
                // this.$refs.editForm.resetFields();
            },
            onBaseCatIdChange: function () {
                // this.info.groupId = 0;
                this.$set(this.info, 'groupId', 0)
                this.getPointList();
            },
            //gnvqSelect组件（工种等级分类）callback方法
            levelChange: function (levelInfo) {
                // console.log(levelInfo)
                // this.info.groupId = 0;
                this.$set(this.info, 'groupId', 0)
                this.getPointList();
            },
            getPointList: function () {
                let data = {
                    levelId: this.info.levelId,
                    baseCatId: this.info.baseCatId,
                };
                this.apis.get('gnvq/point/getList', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.pointList = res.data || []
                            // this.pointList.splice(0, 0, { pointId: '0', pointName: '请选择' });
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            changeItemCat: function (val) {
                this.info.baseCatId = '';
                this.info.gnvqCatId = '';
                this.info.gnvqId = '';
                this.info.levelId = '';
                this.info.pointId = '0';
            },
            changeItemType: function (val) {
                if (val === 1) {
                    return 1
                }
            },
            handleUploadImg (webPath, savePath,) {
                this.$set(this.info, 'examImageUrl', webPath);
                this.$set(this.info, 'examImage', savePath);
            },
            optionUploadImg (webPath, savePath, idx) {
                this.$set(this.info.options[idx], 'optionImageUrl', webPath);
                this.$set(this.info.options[idx], 'optionImage', savePath);
            },
            //添加单选题选项
            optionsAdd: function () {
                if (this.info.options === undefined || this.info.options === null || this.info.options === '') {
                    this.info.options = []
                }
                this.info.options.push({ optionId: '', optionName: '', });
            },
            //删除单选题选项
            optionsRemove: function (index) {
                this.info.options.splice(index, 1);
            },
            //保存继续
            saveContinueeData: async function (formRef) {
                // console.log(this.info)
                this.$refs[formRef].validate((valid) => {
                    if (valid) {
                        if (this.info.examItemCat === 2 && (!this.info.gnvqCatId || !this.info.gnvqId || !this.info.levelId)) {
                            this.$Message.error({
                                content: '请选择工种信息',
                                top: 400,
                                duration: 3
                            });
                            return
                        }
                        let url = "course/examItem/save";
                        this.info.answerDesc = this.$refs.editor.getHtml();
                        this.info.orgId = this.appCookies.getCookieOID();
                        this.info.createBy = this.appCookies.getCookieUID();
                        this.info.createByname = this.appCookies.getCookieUNAME() ;
                        this.info.modifyBy = this.appCookies.getCookieUID();
                        this.info.modifyByname = this.appCookies.getCookieUNAME() ;
                        // 保存数据
                        this.apis.postJson(url, this.info)
                            .then((res) => {
                                if (res.code === 1) {
                                    this.$message({
                                        message: '保存成功',
                                        showClose: true, offset: 200, type: 'success'
                                    });
                                    this.clearInitInfo(this.info.gnvqCatId, this.info.gnvqId, this.info.levelId, this.info.examItemCat, this.info.baseCatId);
                                    this.initTable();
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        showClose: true, offset: 50, type: 'error'
                                    });
                                }
                            }).catch(err => {
                            console.log(err);
                        });
                    }
                })
            },
            clearInitInfo: function (gnvqCatId, gnvqId, levelId, examItemCat, baseCatId) {
                this.info = {
                    orderNum: 0,
                    gnvqCatId: gnvqCatId,
                    gnvqId: gnvqId,
                    levelId: levelId,
                    examItemCat: examItemCat,
                    baseCatId: baseCatId,
                    pointId: '0',
                    options: []
                };
            },
            saveData: async function (formRef) {
                // console.log(this.info)
                this.$refs[formRef].validate((valid) => {
                    if (valid) {
                        if (this.info.examItemCat === 2 && (!this.info.gnvqCatId || !this.info.gnvqId || !this.info.levelId)) {
                            this.$message({
                                message: '请选择工种信息',
                                showClose: true, offset: 200, type: 'error'
                            });
                            return
                        }
                        if (this.info.examItemCat === 1 && !this.info.baseCatId) {
                            this.$message({
                                message: '请选择基础题类别',
                                showClose: true, offset: 200, type: 'error'
                            });
                            return
                        }
                        let url = "course/examItem/save";
                        this.info.answerDesc = this.$refs.editor.getHtml();
                        this.info.orgId = this.appCookies.getCookieOID();
                        this.info.createBy = this.appCookies.getCookieUID();
                        this.info.createByname = this.appCookies.getCookieUNAME() ;
                        this.info.modifyBy = this.appCookies.getCookieUID();
                        this.info.modifyByname = this.appCookies.getCookieUNAME() ;
                        // 保存数据
                        this.apis.postJson(url, this.info)
                            .then((res) => {
                                if (res.code === 1) {
                                    this.$message({
                                        message: '保存成功',
                                        showClose: true, offset: 200, type: 'success'
                                    });
                                    this.editDrawer = false;
                                    this.initTable();
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        showClose: true, offset: 50, type: 'error'
                                    });
                                }
                            }).catch(err => {
                            console.log(err);
                        });
                    }
                })
            },
            toEdit: function (keyId) {
                this.apis.get('course/examItem/getById?examItemId=' + keyId)
                    .then((res) => {
                        console.log(res);
                        if (res.code === 1) {
                            this.info = res.data;
                            this.getPointList();
                            this.editDrawer = true;
                            this.$nextTick(function() {
                                this.$refs.editor.setHtml(this.info.answerDesc);
                                // this.$refs.gnvqSelect.initData(this.info.gnvqCatId, this.info.gnvqId, this.info.levelId);
                            });
                        } else {
                            this.info = {}
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            toDelete: function (keyId, deleteStatus) {
                let title = '确定要删除此条数据？';
                if (deleteStatus === 0) {
                    title = '确定要恢复此条数据？';
                }
                this.$confirm(title, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let data = {
                        examItemId: keyId,
                        deleteStatus: deleteStatus,
                        operUserId: this.appCookies.getCookieUID(),
                        operUserName: this.appCookies.getCookieUNAME()
                    };
                    this.apis.postForm('course/examItem/delete', data)
                        .then((res) => {
                            if (res.code === 1) {
                                this.initTable();
                                this.$message({
                                    message: '操作成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }).catch(() => {
                });
            },
        }
    }
</script>

<style lang="scss" scoped>
</style>
