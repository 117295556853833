<template>
    <el-drawer
        title="考试试题规则设置"
        :visible.sync="examPointRuleEditor"
        :modal-append-to-body="true"
        :wrapperClosable="false"
        :destroy-on-close="true"
        direction="rtl"
        custom-class="demo-drawer"
        size="80%"
        >
        <div v-loading="mainLoading" :element-loading-text="mainLoadingText" class="dtlmain">
            <el-form :model="info" :rules="ruleValidate" ref="editForm" label-width="100px" label-suffix=":">
            <el-divider content-position="left">分数设置</el-divider>
                <el-row :gutter="32">
                    <el-col :span="6">
                        <el-form-item label="多选题" prop="mcqScore">
                            <el-input v-model="info.mcqScore" placeholder="请输入多选题分数" @change="sumScore">
                                <span slot="append">分</span>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="单选题" prop="scqScore">
                            <el-input v-model="info.scqScore" placeholder="请输入单选题分数" @change="sumScore">
                                <span slot="append">分</span>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="判断题" prop="jcqScore">
                            <el-input v-model="info.jcqScore" placeholder="请输入判断题分数" @change="sumScore">
                                <span slot="append">分</span>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="32">
                    <el-col :span="6">
                        <el-form-item label="总分数" prop="examScore">
                            <el-input v-model="info.examScore" placeholder="请输入总分数" >
                                <span slot="append">分</span>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="答题时长" prop="examTime">
                            <el-input v-model="info.examTime" placeholder="请输入分钟" >
                                <span slot="append">分钟</span>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="32">
                    <el-col :span="24">
                        <el-form-item label="随机方式" prop="randomType">
                            <el-radio-group v-model="info.randomType">
                                <el-radio :label="0">试卷题目随机</el-radio>
                                <el-radio :label="1">同一份试卷题目、选项顺序随机</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-row :gutter="16" style="height: calc(100% - 100px)" class="row-container">
                <el-col :span="24" style="height: 80%">
                    <!-- @cell-mouse-enter.once='rowDrop' -->
                    <el-table
                        v-loading="tableLoading"
                        :data="pointTableData"
                        fit
                        highlight-current-row
                        row-key="pointId"
                        show-summary
                        :summary-method="getSummaries"
                        style="width: 100%;"
                        :default-expand-all="true"
                        :tree-props="treeProps"
                        ref="pointEditTable"
                        :indent="24"
                        :cell-style="columnStyle"
                        height="calc(100vh - 300px)">
                        <el-table-column label="知识点(等级管理中设置知识点)" min-width="150px" align="left">
                            <template slot-scope="{row}">
                                <i v-if="row.children.length>0" class="el-icon-folder" style="color: #1890FF; font-size: 16px; margin: 0 3px"></i>
                                <i v-else class="el-icon-tickets"  style="color: #1890FF;font-size: 16px; margin: 0 3px"></i>
                                {{row.pointName}}
                            </template>
                        </el-table-column>
                        <el-table-column label="多选题" align="center">
                            <el-table-column label="最低难度" min-width="80px" align="center">
                                <template slot-scope="{row}">
                                    <el-select v-if="row.children.length===0" v-model="row.mcqGradeMin" placeholder="请选择"  size="small">
                                        <el-option label="请选择" value=""></el-option>
                                        <el-option v-for="item in gradeList" :key="item.id" :value="item.id" :label="item.name" />
                                    </el-select>
                                </template>
                            </el-table-column>
                            <el-table-column label="最大难度" min-width="80px" align="center">
                                <template slot-scope="{row}">
                                    <el-select v-if="row.children.length===0" v-model="row.mcqGradeMax" placeholder="请选择"  size="small">
                                        <el-option label="请选择" value=""></el-option>
                                        <el-option v-for="item in gradeList" :key="item.id" :value="item.id" :label="item.name" />
                                    </el-select>
                                </template>
                            </el-table-column>
                            <el-table-column label="数量" min-width="80px" align="center">
                                <template slot-scope="{ row }">
                                    <el-input v-if="row.children.length===0"  v-model="row.mcqCount" @change="getSummaries" placeholder="请输入多选题数量" size="small"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column label="检查结果" min-width="80px" align="center">
                                <template slot-scope="{ row }">
                                    <span style="color: red">{{row.mcqMsg}}</span>
                                </template>
                            </el-table-column>
                        </el-table-column>
                        <el-table-column label="单选题" align="center">
                            <el-table-column label="最低难度" min-width="80px" align="center">
                                <template slot-scope="{row}">
                                    <el-select v-if="row.children.length===0" v-model="row.scqGradeMin" placeholder="请选择"  size="small">
                                        <el-option label="请选择" value=""></el-option>
                                        <el-option v-for="item in gradeList" :key="item.id" :value="item.id" :label="item.name" />
                                    </el-select>
                                </template>
                            </el-table-column>
                            <el-table-column label="最大难度" min-width="80px" align="center">
                                <template slot-scope="{row}">
                                    <el-select v-if="row.children.length===0" v-model="row.scqGradeMax" placeholder="请选择"  size="small">
                                        <el-option label="请选择" value=""></el-option>
                                        <el-option v-for="item in gradeList" :key="item.id" :value="item.id" :label="item.name" />
                                    </el-select>
                                </template>
                            </el-table-column>
                            <el-table-column label="数量" min-width="80px" align="center">
                                <template slot-scope="{ row }">
                                    <el-input v-if="row.children.length===0"  v-model="row.scqCount" @change="getSummaries" placeholder="请输入多选题数量" size="small"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column label="检查结果" min-width="80px" align="center">
                                <template slot-scope="{ row }">
                                    <span style="color: red">{{row.scqMsg}}</span>
                                </template>
                            </el-table-column>
                        </el-table-column>
                        <el-table-column label="判断题" align="center">
                            <el-table-column label="最低难度" min-width="80px" align="center">
                                <template slot-scope="{row}">
                                    <el-select v-if="row.children.length===0" v-model="row.jcqGradeMin" placeholder="请选择"  size="small">
                                        <el-option label="请选择" value=""></el-option>
                                        <el-option v-for="item in gradeList" :key="item.id" :value="item.id" :label="item.name" />
                                    </el-select>
                                </template>
                            </el-table-column>
                            <el-table-column label="最大难度" min-width="80px" align="center">
                                <template slot-scope="{row}">
                                    <el-select v-if="row.children.length===0" v-model="row.jcqGradeMax" placeholder="请选择"  size="small">
                                        <el-option label="请选择" value=""></el-option>
                                        <el-option v-for="item in gradeList" :key="item.id" :value="item.id" :label="item.name" />
                                    </el-select>
                                </template>
                            </el-table-column>
                            <el-table-column label="数量" min-width="80px" align="center">
                                <template slot-scope="{ row }">
                                    <el-input v-if="row.children.length===0"  v-model="row.jcqCount" @change="getSummaries" placeholder="请输入判断题数量" size="small"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column label="检查结果" min-width="80px" align="center">
                                <template slot-scope="{ row }">
                                    <span style="color: red">{{row.jcqMsg}}</span>
                                </template>
                            </el-table-column>
                        </el-table-column>
                    </el-table>
                </el-col>
            </el-row>
        </div>
        <div class="demo-drawer__footer">
            <el-button @click="examPointRuleEditor = false">关闭</el-button>
            <el-button  type="success" @click="check()">规则检查</el-button>
            <el-button  type="primary" @click="saveData('editForm')">保存</el-button>
        </div>
    </el-drawer>
</template>
<script>
export default {
    components: {
    },
    filters: {
    },
    data() {
        return {
            examPointRuleEditor: false,
            mainLoading: false,
            mainLoadingText: '正在处理中，请稍等',
            ruleId: '',
            pointTableData: [],
            tableLoading: false,
            treeProps: {
                children: 'children',
                hasChildren: 'hasChildren'
            },
            gradeList: [
                { id: 1, name: '等级1' },
                { id: 2, name: '等级2' },
                { id: 3, name: '等级3' },
                { id: 4, name: '等级4' },
                { id: 5, name: '等级5' },
                { id: 6, name: '等级6' },
                { id: 7, name: '等级7' },
                { id: 8, name: '等级8' },
                { id: 9, name: '等级9' },
                { id: 10, name: '等级10' }
            ],
            //表单验证规则
            ruleValidate: {
                // 基本信息
                mcqScore: [
                    { required: true, message: '请输入分数', trigger: 'blur' }
                ],
                scqScore: [
                    { required: true, message: '请输入分数', trigger: 'blur' }
                ],
                jcqScore: [
                    { required: true, message: '请输入分数', trigger: 'blur' }
                ],
                dcqScore: [
                    { required: true, message: '请输入分数', trigger: 'blur' }
                ],
                examScore: [
                    { required: true, message: '请输入总分数', trigger: 'blur' }
                ],
                examTime: [
                    { required: true, message: '请输入答题时长', trigger: 'blur' }
                ],
                randomType: [
                    { required: true, message: '请选择随机方式', trigger: 'blur' }
                ],
            },
            info: {
                mcqScore: 0,
                scqScore: 0,
                jcqScore: 0,
                examTime: '',
                randomType: 0
            },
            totalSums: []
        }
    },
    // watch: {
    //     pointTableData: {
    //         handler (val) {
    //             // this.getSummaries(val)
    //             let that = this;
    //             this.$nextTick(() => {
    //                 // that.$refs.pointEditTable.doLayout();
    //                 that.getSummaries()
    //             });
    //         },
    //         deep: true,
    //         immediate: true
    //     }
    // },
    mounted () {
    },
    methods: {
        columnStyle({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 5 || columnIndex === 6 || columnIndex === 7 || columnIndex === 8) {
                return "background:#E8F4FF";
            }
        },
        /**
         * 初始化数据
         */
        open: function (ruleId) {
            this.examPointRuleEditor = true;
            this.ruleId = ruleId;
            this.getPointRuleList();
        },
        getPointRuleList: function () {
            this.mainLoading = true;
            this.mainLoadingText = '正在查询数据，请稍等';
            let data = {
                ruleId: this.ruleId
            };
            this.apis.get('course/examPaperRule/point/getList', data)
                .then((res) => {
                    if (res.code === 1) {
                        this.info = res.data;
                        this.info.ruleType = 1;
                        this.pointTableData = res.data.pointList || [];
                        this.$nextTick(() => {
                            this.$refs['pointEditTable'].doLayout();
                        });
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                    this.mainLoading = false;
                }).catch((err) => {
                this.mainLoading = false;
                console.log(err)
            });
        },
        //批量保存
        saveData: function(formRef){
            this.$refs[formRef].validate((valid) => {
                if (valid) {
                    let url = "course/examPaperRule/point/batchSave";
                    this.info.pointList = this.pointTableData;
                    this.info.createBy = this.appCookies.getCookieUID();
                    this.info.createByname = this.appCookies.getCookieUNAME();
                    this.info.modifyBy = this.appCookies.getCookieUID();
                    this.info.modifyByname = this.appCookies.getCookieUNAME();
                    this.apis.postJson(url, this.info)
                        .then((res) => {
                            if (res.code === 1) {
                                this.$message({
                                    message: '保存成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                                this.$emit('callback', this.ruleId)
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }
            })
        },
        check: function(){
            this.mainLoading = true;
            let url = "course/examPaperRule/point/check";
            this.info.pointList = this.pointTableData;
            this.apis.postJson(url, this.info)
                .then((res) => {
                    if (res.code === 1) {
                        this.pointTableData = res.data
                        this.$message({
                            message: '检测完成请查看结果',
                            showClose: true, offset: 200, type: 'success'
                        });
                    } else {
                        this.$message({
                            message: res.msg,
                            showClose: true, offset: 50, type: 'error'
                        });
                    }
                    this.mainLoading = false;
                }).catch(err => {
                this.mainLoading = false;
                console.log(err);
            });
        },
        sumScore: function () {
            if (!this.info.mcqScore) {
                this.info.mcqScore = 0;
            }
            if (!this.info.scqScore) {
                this.info.scqScore = 0;
            }
            if (!this.info.jcqScore) {
                this.info.jcqScore = 0;
            }
            let examScore = parseInt(this.mcqCount, 0) * parseFloat(this.info.mcqScore) +
                parseInt(this.scqCount, 0) * parseFloat(this.info.scqScore) +
                parseInt(this.jcqCount, 0) * parseFloat(this.info.jcqScore);
            // let examScore = parseFloat(this.info.mcqScore) + parseFloat(this.info.scqScore) + parseFloat(this.info.jcqScore)
            this.$set(this.info, 'examScore', examScore)
        },
        getSummaries(param) {
            // const { columns, data } = param;
            let sums = [];
            sums[0] = '合计';
            sums[1] = '';
            sums[2] = '';
            sums[3] = 0;
            sums[4] = '';
            sums[5] = '';
            sums[6] = '';
            sums[7] = 0;
            sums[8] = '';
            sums[9] = '';
            sums[10] = '';
            sums[11] = 0;
            sums[12] = '';
            this.summary(sums, this.pointTableData);
            this.mcqCount = sums[3];
            this.scqCount = sums[7];
            this.jcqCount = sums[11];
            this.sumScore()
            return sums;
        },
        summary: function (sums, datas) {
            // const values = datas.map(item => Number(item[column.property]));
            if (datas && datas.length > 0) {
                datas.forEach(data => {
                    if (!isNaN(data['mcqCount'])) {
                        sums[3] = sums[3] + Number(data['mcqCount'])
                    }
                    if (!isNaN(data['scqCount'])) {
                        sums[7] = sums[7] + Number(data['scqCount'])
                    }
                    if (!isNaN(data['jcqCount'])) {
                        sums[11] = sums[11] + Number(data['jcqCount'])
                    }
                    if (data.children && data.children.length >0) {
                        this.summary(sums, data.children);
                    }
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    /deep/ .el-table .cell{
        display: flex;
        align-items: center;
    }
    .qr {
        text-align: center;
        img {
            height: 130px;
            width: 130px;
        }
    }
    ::v-deep {
        .el-table__row .cell {
        }
    }
</style>
