<template>
    <el-dialog
        title="试题库选择"
        :visible.sync="examStoreDialog"
        :destroy-on-close="true"
        :close-on-click-modal="false"
        :modal="false"
        width="50%">
        <el-form :model="info" :rules="ruleValidate" ref="examTypeForm" label-width="150px" label-suffix=":">
            <el-form-item label="试题分类" prop="examItemCat" >
                <el-radio-group v-model="info.examItemCat" @change="changeItemCat">
                    <el-radio :label="1">基础课程题</el-radio>
                    <el-radio :label="2">专业课程题</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="基础题类别" prop="baseCatId" v-if="info.examItemCat==1">
                <el-select v-model="info.baseCatId" @change="onBaseCatIdChange">
                    <el-option value="">请选择</el-option>
                    <el-option v-for="item in baseCatList" :value="item.dictId" :label="item.dictName"  :key="item.dictId"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="工种等级" prop="categoryId" v-if="info.examItemCat==2">
                <GnvqSelect ref="gnvqSelect" :gnvqCatId.sync="info.gnvqCatId" :gnvqId.sync="info.gnvqId"
                            :levelId.sync="info.levelId"  @callback="levelChange" @callbackName="callbackName"></GnvqSelect>
            </el-form-item>
            <el-form-item label="分组" v-show="info.levelId || info.baseCatId">
                <GroupEditorSelect ref="groupSelect"
                                   apiPrefix="/course/examItem/group"
                                   :editable="false"
                                   :groupId.sync="info.groupId"
                                   :baseCatId="info.baseCatId"
                                   :gnvqId="info.gnvqId"
                                   :levelId="info.levelId">
                </GroupEditorSelect>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="examStoreDialog = false">取 消</el-button>
            <el-button @click="saveData('examTypeForm')">确 认</el-button>
        </span>
    </el-dialog>
</template>

<script>
import GnvqSelect from "@v/components/GnvqSelect";
import GroupEditorSelect from "@v/components/GroupEditorSelect";
export default {
    components: {
        GnvqSelect,
        GroupEditorSelect
    },
    data () {
        return {
            examStoreDialog: false,
            gnvqCatId: '',
            gnvqId: '',
            levelId: '',
            info: {
                examItemCat: 1,
                gnvqCatId: '',
                gnvqId: '',
                levelId: '',
                baseCatId: '',
            },
            ruleValidate: {
                examItemCat: [
                    { required: true, type: 'number', message: '请选择试题分类', trigger: 'change' }
                ]
            },
            baseCatList: [],
        };
    },
    mounted () {
    },
    methods: {
        open: function (gnvqCatId, gnvqId, levelId) {
            this.examStoreDialog = true;
            this.gnvqCatId = gnvqCatId;
            this.gnvqId = gnvqId;
            this.levelId = levelId;
            this.info = {
                examItemCat: 2,
                gnvqCatId: gnvqCatId,
                gnvqCatName: '',
                gnvqId: gnvqId,
                gnvqName: '',
                levelId: levelId,
                levelName: '',
                baseCatId: '',
                baseCatName: '',
            }
            this.getDictList()
        },
        changeItemCat: function (val) {
            if (this.info.examItemCat === 1) {
                this.info.gnvqCatId = '';
                this.info.gnvqCatName = '';
                this.info.gnvqId = '';
                this.info.gnvqName = '';
                this.info.levelId = '';
                this.info.levelName = '';
            } else {
                this.info.gnvqCatId = this.gnvqCatId;
                this.info.gnvqId = this.gnvqId;
                this.info.levelId = this.levelId;
                this.info.baseCatId = '';
                this.info.baseCatName = '';
            }
        },
        getDictList: function () {
            this.apis.get('system/dict/getList?typeCode=0004')
                .then((res) => {
                    if (res.code === 1) {
                        this.baseCatList = res.data || []
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        onBaseCatIdChange: function () {
            this.info.groupId = '';
            this.baseCatList.forEach(item => {
                if (item.dictId === this.info.baseCatId) {
                    this.info.baseCatName = item.dictName
                }
            })
        },
        levelChange: function (levelInfo) {
            this.info.groupId = '';
        },
        callbackName: function (gnvqName, levelName) {
            this.info.gnvqName = gnvqName;
            this.info.levelName = levelName;
        },
        saveData: async function (formRef) {
            this.$refs[formRef].validate((valid) => {
                if (valid) {
                    if (this.info.examItemCat === 2 && (!this.info.gnvqCatId || !this.info.gnvqId || !this.info.levelId)) {
                        this.$message({
                            message: '请选择工种信息',
                            showClose: true, offset: 200, type: 'error'
                        });
                        return
                    }
                    if (this.info.examItemCat === 1 && !this.info.baseCatId) {
                        this.$message({
                            message: '请选择基础题类别',
                            showClose: true, offset: 200, type: 'error'
                        });
                        return
                    }
                    if (this.info.examItemCat === 2) {
                        if (!this.info.gnvqName) {
                            this.info.gnvqName = this.$refs.gnvqSelect.getGnvqName()
                        }
                        if (!this.info.levelName) {
                            this.info.levelName = this.$refs.gnvqSelect.getLevelName()
                        }
                    }
                    this.$emit('callback', this.info);
                    this.examStoreDialog = false;
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
</style>
