<template>
    <el-drawer
        title="试题管理"
        :visible.sync="editDrawer"
        :modal-append-to-body="true"
        :append-to-body="true"
        :wrapperClosable="false"
        :destroy-on-close="true"
        direction="rtl"
        custom-class="demo-drawer"
        ref="editDrawer"
        size="70%"
    >
        <div class="demo-drawer__content">
            <el-form :model="info" :rules="ruleValidate" ref="editForm" label-width="100px" label-suffix=":">
                <el-row :gutter="32">
                    <el-col>
                        <el-form-item label="试题名称" prop="itemName">
                            <el-input v-model="info.itemName" type="textarea" :rows="2"  placeholder="请输入试题名称"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <el-form-item label="试题类型" prop="itemType" >
                            <el-radio-group v-model="info.itemType" @change="changeItemType">
                                <el-radio :label="1">多选题</el-radio>
                                <el-radio :label="2">单选题</el-radio>
                                <el-radio :label="3">判断题</el-radio>
                                <el-radio :label="4">论述题</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="序号" prop="orderNum">
                            <span slot="label">
                                序号：
                                <el-tooltip  content="显示的顺序，不控制可输入0" effect="light" placement="top">
                                    <i class="el-icon-question" style="color:red"></i>
                                </el-tooltip>
                            </span>
                            <el-input v-model="info.orderNum"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="分数" prop="itemScore">
                            <el-input v-model="info.itemScore"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <el-form-item label="试题图片">
                            <SingleUploadImg @callback="handleUploadImg" :imageUrl="info.examImageUrl"
                                             :width="200" :height="100"></SingleUploadImg>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="试题选项" v-show="info.itemType==1 || info.itemType==2">
                    <el-row :gutter="16">
                        <el-col :span="3" :offset="2">
                            <span>编号(A/B/C/D)</span>
                        </el-col>
                        <el-col :span="3">
                            <span>选项值(1/2/3/4)</span>
                        </el-col>
                        <el-col :span="10">
                            <span>选项标签</span>
                        </el-col>
                        <el-col :span="3">
                            <span>设置为正确答案</span>
                        </el-col>
                    </el-row>
                    <el-row :gutter="16" v-for="(item, index) in info.options" :key="index" style="margin: 5px 0">
                        <el-col :span="2" style="text-align: right">
                            <span>选项{{index+1}}:</span>
                        </el-col>
                        <el-col :span="3">
                            <el-input type="text" v-model="item.optionCode" placeholder="请输入选项编号"></el-input>
                        </el-col>
                        <el-col :span="3">
                            <el-input type="text" v-model="item.optionValue" placeholder="请输入选项值"></el-input>
                        </el-col>
                        <el-col :span="10">
                            <div style="display: flex">
                                <el-input type="text" v-model="item.optionLable" placeholder="请输入选项描述"></el-input>
                                <SingleUploadImg @callback="optionUploadImg" :index="index" :imageUrl="item.optionImageUrl"
                                                 :width="100" :height="30"></SingleUploadImg>
                            </div>
                        </el-col>
                        <el-col :span="3">
                            <el-checkbox v-model="item.trueFlag">是</el-checkbox>
                        </el-col>
                        <el-col :span="3">
                            <el-button @click="optionsRemove(index)">删除</el-button>
                        </el-col>
                    </el-row>
                    <el-row :gutter="16" >
                        <el-col :span="16" :offset="2">
                            <el-button plain @click="optionsAdd" icon="el-icon-plus" style="width:100%">添加</el-button>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-row :gutter="32" v-show="info.itemType==3">
                    <el-col>
                        <el-form-item label="试题答案" prop="itemAnswer">
                            <el-radio-group v-model="info.itemAnswer">
                                <el-radio label="Y">正确</el-radio>
                                <el-radio label="N">错误</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="32">
                    <el-col>
                        <el-form-item label="答案解释" prop="answerDesc">
                            <editor ref="editor" :height="200"></editor>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <div class="demo-drawer__footer">
            <el-button @click="editDrawer = false">关闭</el-button>
            <el-button  type="primary" v-show="!info.itemId" @click="saveContinueeData('editForm')">保存继续</el-button>
            <el-button  type="primary" @click="saveData('editForm')">保存</el-button>
        </div>
    </el-drawer>
</template>

<script>
import SingleUploadImg from "@c/upload/SingleUploadImg.vue";
import editor from "@c/editor/editor.vue";

export default {
    components: {
        SingleUploadImg,
        editor,
    },
    data () {
        const validateNumber = (rule, value, callback) => {
            if (!Number.isInteger(+value)) {
                callback(new Error('请输入数值'));
            } else {
                callback();
            }
        };
        const validateAnswer = (rule, value, callback) => {
            // console.log(value)
            if (this.info.itemType === 3 && !value) {
                return callback(new Error('请选择试题答案'));
            } else {
                callback();
            }
        };
        return {
            editDrawer: false,
            tableLoading: false,
            //表单验证规则
            ruleValidate: {
                itemName: [
                    { required: true, message: '请输入试题名称', trigger: 'blur' }
                ],
                itemType: [
                    { required: true, type: 'number', message: '请选择试题类型', trigger: 'change' }
                ],
                itemScore: [
                    { required: true, message: '请输入分数', trigger: 'change' }
                ],
                itemAnswer: [
                    { required: true, validator: validateAnswer, trigger: 'change' }
                ],
                orderNum: [
                    { validator: validateNumber, trigger: 'blur' }
                ]
            },
            paperId: '',
            itemId: '',
            info: {
                paperId: ''
            }
        };
    },
    mounted () {
    },
    methods: {
        open: function (paperId, itemId) {
            this.paperId = paperId
            this.editDrawer = true;
            if (itemId) {
                this.itemId = itemId
                this.getById()
            } else {
                this.info = {
                    paperId: paperId,
                    options: []
                }
                this.$nextTick(function() {
                    this.$refs.editor.setHtml('');
                });
            }
        },
        getById: function () {
            this.apis.get('course/high/paper/item/getById?itemId=' + this.itemId)
                .then((res) => {
                    if (res.code === 1) {
                        this.info = res.data;
                        this.$nextTick(function() {
                            this.$refs.editor.setHtml(this.info.answerDesc);
                        });
                    } else {
                        this.info = {}
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        changeItemType: function (val) {
            if (val === 1) {
                return 1
            }
        },
        handleUploadImg (webPath, savePath,) {
            this.$set(this.info, 'examImageUrl', webPath);
            this.$set(this.info, 'examImage', savePath);
        },
        optionUploadImg (webPath, savePath, idx) {
            this.$set(this.info.options[idx], 'optionImageUrl', webPath);
            this.$set(this.info.options[idx], 'optionImage', savePath);
        },
        //添加单选题选项
        optionsAdd: function () {
            if (this.info.options === undefined || this.info.options === null || this.info.options === '') {
                this.info.options = []
            }
            this.info.options.push({ optionId: '', optionName: '', });
        },
        //删除单选题选项
        optionsRemove: function (index) {
            this.info.options.splice(index, 1);
        },
        //保存继续
        saveContinueeData: async function (formRef) {
            // console.log(this.info)
            this.$refs[formRef].validate((valid) => {
                if (valid) {
                    if (this.info.examItemCat === 2 && (!this.info.gnvqCatId || !this.info.gnvqId || !this.info.levelId)) {
                        this.$Message.error({
                            content: '请选择工种信息',
                            top: 400,
                            duration: 3
                        });
                        return
                    }
                    let url = "course/high/paper/item/save";
                    this.info.answerDesc = this.$refs.editor.getHtml();
                    this.info.paperId = this.paperId;
                    this.info.createBy = this.appCookies.getCookieUID();
                    this.info.createByname = this.appCookies.getCookieUNAME() ;
                    this.info.modifyBy = this.appCookies.getCookieUID();
                    this.info.modifyByname = this.appCookies.getCookieUNAME() ;
                    // 保存数据
                    this.apis.postJson(url, this.info)
                        .then((res) => {
                            if (res.code === 1) {
                                this.$message({
                                    message: '保存成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                                this.$emit('callback')
                                this.clearInitInfo();
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }
            })
        },
        clearInitInfo: function () {
            this.info = {
                paperId: this.paperId,
                options: []
            }
        },
        saveData: async function (formRef) {
            // console.log(this.info)
            this.$refs[formRef].validate((valid) => {
                if (valid) {
                    if (this.info.examItemCat === 2 && (!this.info.gnvqCatId || !this.info.gnvqId || !this.info.levelId)) {
                        this.$message({
                            message: '请选择工种信息',
                            showClose: true, offset: 200, type: 'error'
                        });
                        return
                    }
                    if (this.info.examItemCat === 1 && !this.info.baseCatId) {
                        this.$message({
                            message: '请选择基础题类别',
                            showClose: true, offset: 200, type: 'error'
                        });
                        return
                    }
                    let url = "course/high/paper/item/save";
                    this.info.answerDesc = this.$refs.editor.getHtml();
                    this.info.paperId = this.paperId;
                    this.info.createBy = this.appCookies.getCookieUID();
                    this.info.createByname = this.appCookies.getCookieUNAME() ;
                    this.info.modifyBy = this.appCookies.getCookieUID();
                    this.info.modifyByname = this.appCookies.getCookieUNAME() ;
                    // 保存数据
                    this.apis.postJson(url, this.info)
                        .then((res) => {
                            if (res.code === 1) {
                                this.$message({
                                    message: '保存成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                                this.$emit('callback')
                                this.editDrawer = false;
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.item-box {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    border: dotted 1px #0e9a00;
    .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px 10px;
    }
}
</style>
