<template>
    <div class="app-container"
         v-loading="mainLoading"
         :element-loading-text="mainLoadingText">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <el-row :gutter="8" class="row-container">
            <el-col :span="5">
                <el-card class="fix-height-card" shadow="never"  style="height:calc(100vh - 146px);">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>工种等级</span>
                    </div>
                    <GnvqTree ref="gnvqTree" @callback="toQuery"></GnvqTree>
                </el-card>
            </el-col>
            <el-col :span="19">
                <el-card class="box-card" shadow="never">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>专项练习题库</span>
                    </div>
                    <el-row class="query-container">
                        <el-col :span="24">
                            <el-form :inline="true">
                                <el-form-item label="">
                                    <el-input v-model="queryParams.keyword" placeholder="查询关键字" style="width:100px"></el-input>
                                </el-form-item>

                                <el-form-item label=""  v-if="this.privRange.havePriv()">
                                    <el-select filterable v-model="queryParams.orgId"  placeholder="请选择报名机构" style="width:150px">
                                        <el-option value="">请选择</el-option>
                                        <el-option v-for="item in orgList" :value="item.orgId" :label="item.orgName"  :key="item.orgId"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-select v-model="queryParams.deleteStatus" placeholder="请选择状态" style="width:100px">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="正常" :value="0"></el-option>
                                        <el-option label="已删除" :value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                                </el-form-item>
                                <el-button-group style="float: right">
                                    <el-button class="filter-item" type="success" round icon="el-icon-edit" @click="toAdd">新增</el-button>
                                </el-button-group>
                            </el-form>
                        </el-col>
                    </el-row>
                    <el-row :gutter="32" class="table-container">
                        <el-col>
                            <el-table
                                v-loading="tableLoading"
                                :data="tableData"
                                fit
                                highlight-current-row
                                style="width: 100%;"
                                height="calc(100vh - 300px)"
                                ref="selectTable"
                                :indent="20"
                            >
                                <el-table-column
                                    type="selection"
                                    width="55">
                                </el-table-column>
                                <el-table-column label="标题" prop="paperName" min-width="150px" align="left" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="描述" prop="paperDesc" min-width="120px" align="left"  :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="工种等级" width="120px" align="center">
                                    <template slot-scope="{row}">
                                        {{ row.gnvqName + '-' + row.levelName}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="可用状态" prop="dataStatus" min-width="100px" align="center" :show-overflow-tooltip="true">
                                    <template slot-scope="{row}">
                                        <el-switch
                                            v-model="row.dataStatus"
                                            :active-value="1"
                                            :inactive-value="0"
                                            active-color="#2d8cf0"
                                            inactive-color="#efefef"
                                            @change="toLock(row)">
                                        </el-switch>
                                    </template>
                                </el-table-column>
                                <el-table-column label="答题时长" prop="examTime" align="center" width="80"></el-table-column>
                                <el-table-column label="总分数" prop="examScore" align="center" width="80"></el-table-column>
                                <el-table-column label="通过分数" prop="passScore" align="center" width="80"></el-table-column>

                                <el-table-column label="所属机构" prop="orgName" min-width="100px" align="left"  :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="状态" min-width="100px" align="center">
                                    <template slot-scope="{row}">
                                        <el-tag :type="row.deleteStatus | colorFilter">
                                            {{ row.deleteStatus  | deleteStatusFilter}}
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="创建时间" width="140px" align="center">
                                    <template slot-scope="{row}">
                                        <span>{{ row.createTime | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作" align="center" width="220" fixed="right" class-name="small-padding fixed-width">
                                    <template slot-scope="{row}">
                                        <el-button v-if="row.deleteStatus===0" type="text" size="mini" icon="el-icon-edit" @click="toEdit(row.paperId)">修改</el-button>
                                        <el-button v-if="row.deleteStatus===0" type="text" size="mini"  icon="el-icon-delete" @click="toDelete(row.paperId, 1)">删除</el-button>
                                        <el-button v-if="row.deleteStatus===1" type="text" size="mini"  icon="el-icon-refresh-right" @click="toDelete(row.paperId, 0)">恢复</el-button>
                                        <el-button v-if="row.deleteStatus===0" type="text" size="mini" icon="el-icon-edit" @click="toDefine(row.paperId)">定义</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                                        :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                                        :first-zero="0"
                                        @pagination="initTable"/>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
        <el-dialog
            title="试卷信息"
            :visible.sync="editDialog"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            width="700px">
            <el-form :model="info" :rules="ruleValidate" ref="editForm" label-width="140px" label-suffix=":">
                <el-row>
                    <el-col :span="20">
                        <el-form-item label="试卷标题" prop="paperName">
                            <el-input v-model="info.paperName"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="20">
                        <el-form-item label="试卷说明" prop="paperDesc">
                            <el-input v-model="info.paperDesc"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="20">
                        <el-form-item label="工种等级" prop="categoryId">
                            <GnvqSelect ref="gnvqSelect" :gnvqCatId.sync="info.gnvqCatId" :gnvqId.sync="info.gnvqId"
                                        :levelId.sync="info.levelId"></GnvqSelect>
                        </el-form-item>
                    </el-col>
                    <el-col :span="20">
                        <el-form-item label="总分数" prop="examScore">
                            <el-input v-model="info.examScore" placeholder="请输入总分数" >
                                <span slot="append">分</span>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="20">
                        <el-form-item label="通过分数" prop="passScore">
                            <el-input v-model="info.passScore" placeholder="请输入通过分数" >
                                <span slot="append">分</span>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="20">
                        <el-form-item label="答题时长" prop="examTime">
                            <el-input v-model="info.examTime" placeholder="请输入分钟" >
                                <span slot="append">分钟</span>
                            </el-input>
                        </el-form-item>
                    </el-col>

                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editDialog = false">关 闭</el-button>
                <el-button type="primary" @click="saveData('editForm')">保存</el-button>
            </span>
        </el-dialog>
        <HighfreqPaperListDrawer ref="highfreqPaperListDrawer"></HighfreqPaperListDrawer>
    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import GnvqTree from "@v/components/GnvqTree";
import GnvqSelect from "@v/components/GnvqSelect.vue";
import HighfreqPaperListDrawer from './components/HighfreqPaperListDrawer'
export default {
    components: {
        Pagination,
        Breadcrumb,
        GnvqTree,
        GnvqSelect,
        HighfreqPaperListDrawer
    },
    filters: {
        deleteStatusFilter(status) {
            const statusMap = {
                0: '正常',
                1: '已删除',
            };
            return statusMap[status]
        },
        colorFilter(status) {
            const statusMap = {
                0: 'success',
                1: 'danger'
            };
            return statusMap[status]
        },
    },
    data () {
        return {
            mainLoading: false,
            mainLoadingText: '正在查询数据，请稍等',
            crumbData: [
                {
                    path: '',
                    name: '首页'
                },
                {
                    path: '',
                    name: '专项练习题库'
                }
            ],
            queryParams: {
                pageNumber: 1,
                pageSize: 10,
                totalRecord: 0,
                typeCode: '',
                keyword: '',
                deleteStatus: 0,
                dataStatus: '',
                orgId: this.appCookies.getCookieOID(),
                gnvqCatId: '',
                gnvqId: '',
                levelId: '',
            },
            tableData: [],
            tableLoading: false,
            orgList: [],
            editDialog: false,
            info: {},
            //表单验证规则
            ruleValidate: {
                // 基本信息
                paperName: [
                    { required: true, message: '请输入名称', trigger: 'blur' }
                ],
                gnvqId: [
                    { required: true, message: '选择工种', trigger: 'change' }
                ],
                levelId: [
                    { required: true, message: '选择等级', trigger: 'change' }
                ],
                examScore: [
                    { required: true, message: '请输入总分数', trigger: 'blur' }
                ],
                passScore: [
                    { required: true, message: '请输入通过分数', trigger: 'blur' }
                ],
                examTime: [
                    { required: true, message: '请输入答题时长', trigger: 'blur' }
                ],
            },
        };
    },
    mounted () {
        this.initTable();
        this.getOrgList();
    },
    methods: {
        toQuery: function (data) {
            // console.log(data)
            this.queryParams.gnvqCatId = '';
            this.queryParams.gnvqId = '';
            this.queryParams.levelId = '';
            if (data.type === 'CAT') {
                this.queryParams.gnvqCatId = data.id;
            } else if (data.type === 'INFO') {
                this.queryParams.gnvqCatId = data.categoryId;
                this.queryParams.gnvqId = data.id;
            } else {
                this.queryParams.gnvqCatId = data.categoryId;
                this.queryParams.gnvqId = data.parentId;
                this.queryParams.levelId = data.id;
            }
            this.refreshTable();
        },
        initTable: function () {
            this.tableLoading = true;
            this.apis.postForm('course/high/paper/query', this.queryParams)
                .then((res) => {
                    if (res.code === 1) {
                        this.tableData = res.data;
                        this.queryParams.totalRecord = res.count;
                        console.log(res);
                        this.tableLoading = false;
                    } else {
                        this.tableLoading = false;
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                this.tableLoading = false;
                console.log(err)
            });
        },
        refreshTable: function () {
            this.queryParams.pageNumber = 1;
            this.initTable();
        },
        getOrgList: function () {
            if (!this.privRange.havePriv()){
                return
            }
            this.apis.get('system/org/getList?orgId=' + this.appCookies.getCookieOID())
                .then((res) => {
                    console.log(res)
                    if (res.code === 1) {
                        this.orgList = res.data || [];
                    } else {
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        toAdd: function () {
            this.info = {
                asqName: ''
            };
            this.editDialog = true;
        },
        saveData: async function (formRef) {
            this.$refs[formRef].validate((valid) => {
                if (valid) {
                    let url = "course/high/paper/save";
                    this.info.orgId = this.appCookies.getCookieOID();
                    this.info.createBy = this.appCookies.getCookieUID();
                    this.info.createByname = this.appCookies.getCookieTNAME();
                    this.info.modifyBy = this.appCookies.getCookieUID();
                    this.info.modifyByname = this.appCookies.getCookieTNAME();
                    // 保存数据
                    this.apis.postJson(url, this.info)
                        .then((res) => {
                            if (res.code === 1) {
                                this.editDialog = false;
                                this.initTable();
                                this.$message({
                                    message: '保存成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }
            })
        },
        toEdit: function (keyId) {
            this.apis.get('course/high/paper/getById?paperId=' + keyId)
                .then((res) => {
                    console.log(res);
                    if (res.code === 1) {
                        this.info = res.data;
                        this.editDialog = true;
                    } else {
                        this.info = {}
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        toLock: function (row) {
            //console.log(el-row)
            let data = {
                paperId: row.paperId,
                dataStatus: row.dataStatus,
                operUserId: this.appCookies.getCookieUID(),
                operUserName: this.appCookies.getCookieUNAME()
            };
            this.apis.postForm('course/high/paper/updDataStatus', data)
                .then((res) => {
                    if (res.code === 1) {
                        this.$message({
                            message: '操作成功',
                            showClose: true, offset: 200, type: 'success'
                        });
                    } else {
                        this.$message({
                            message: res.msg,
                            showClose: true, offset: 50, type: 'error'
                        });
                    }
                }).catch(err => {
                console.log(err);
            });
        },
        toDelete: function (id, deleteStatus) {
            let title = '确定要删除此条数据？';
            if (deleteStatus === 0) {
                title = '确定要恢复此条数据？';
            }
            this.$confirm(title, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    paperId: id,
                    deleteStatus: deleteStatus,
                    operUserId: this.appCookies.getCookieUID(),
                    operUserName: this.appCookies.getCookieUNAME()
                };
                this.apis.postForm('course/high/paper/delete', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.initTable();
                            this.$message({
                                message: '操作成功',
                                showClose: true, offset: 200, type: 'success'
                            });
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            }).catch(() => {
            });
        },
        toDefine: function (paperId) {
            this.$refs.highfreqPaperListDrawer.open(paperId)
        },
    }
}
</script>

<style lang="scss" scoped>
</style>
