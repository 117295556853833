<template>
    <div class="app-container">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <el-row :gutter="8" class="row-container">
            <!-- 工种分类 -->
            <el-col :span="5">
                <el-card class="fix-height-card" shadow="never" style="height:calc(100vh - 146px);">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>工种等级分类</span>
                    </div>
                    <GnvqTree ref="gnvqTree" @callback="toQuery"></GnvqTree>
                </el-card>
            </el-col>
            <!-- 列表主体 -->
            <el-col :span="19">
                <el-card class="box-card" shadow="never">
                    <div slot="header" class="clearfix">
                        <i class="el-icon-s-operation"></i>
                        <span>试题列表</span>
                        <div style="float: right; padding: 0px 0">
<!--                            <el-checkbox size="mini" v-model="markConfig.showSelect">显示标引</el-checkbox>-->
<!--                            <el-input-number v-model="markConfig.fontSize" @change="setFontSize" :min="1" :max="50" size="mini"></el-input-number>-->
                        </div>
                    </div>
                    <!-- 搜索查询 -->
                    <el-row class="query-container">
                        <el-col>
                            <el-form :inline="true">
                                <el-form-item label="">
                                    <el-input v-model="queryParams.keyword" placeholder="查询关键字" style="width:120px"></el-input>
                                </el-form-item>
                                <el-form-item label="">
                                    <el-select v-model="queryParams.useStatus" placeholder="请选择" style="width:100px">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="禁用" value="0"></el-option>
                                        <el-option label="可用" value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-select v-model="queryParams.deleteStatus" placeholder="请选择" style="width:100px">
                                        <el-option label="全部" value=""></el-option>
                                        <el-option label="正常" value="0"></el-option>
                                        <el-option label="已删除" value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                                </el-form-item>
                                <el-button-group style="float: right">
                                    <el-button class="filter-item" type="success" round icon="el-icon-edit" @click="toAdd">新增</el-button>
                                </el-button-group>
                            </el-form>
                        </el-col>
                    </el-row>
                    <!-- 列表内容 -->
                    <el-row class="table-container">
                        <el-col>
                            <el-table
                                v-loading="tableLoading"
                                :data="tableData"
                                fit
                                stripe
                                highlight-current-el-row
                                style="width: 100%;"
                                height="calc(100vh - 300px)"
                                ref="queryTable"
                                :indent="20"
                            >
                                <el-table-column label="试题名称" prop="ruleName" min-width="200px" align="left" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="答题时长" prop="examTime" min-width="100px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="总分数" prop="examScore" min-width="80px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="工种" prop="gnvqName" min-width="150px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="等级" prop="levelName" min-width="100px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="基础题类别" prop="baseCatName" min-width="150px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="可用状态" prop="useStatus" min-width="100px" :show-overflow-tooltip="true">
                                    <template slot-scope="{row}">
                                        <el-switch
                                            v-model="row.useStatus"
                                            :active-value="1"
                                            :inactive-value="0"
                                            active-color="#2d8cf0"
                                            inactive-color="#efefef"
                                            @change="toLock(row)">
                                        </el-switch>
                                    </template>
                                </el-table-column>
                                <el-table-column label="多选题(专业/分数)" min-width="150px" :show-overflow-tooltip="true">
                                    <template slot-scope="{ row }">
                                        <span>{{row.mcqCount + '/' + row.mcqScore}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="单选题(基础/专业/分数)" min-width="190px" :show-overflow-tooltip="true">
                                    <template slot-scope="{ row }">
                                        <span>{{row.baseScqCount + '/' + row.scqCount + '/' + row.scqScore}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="判断题(基础/专业/分数)" min-width="190px" :show-overflow-tooltip="true">
                                    <template slot-scope="{ row }">
                                        <span>{{row.baseJcqCount + '/' + row.jcqCount + '/' + row.jcqScore}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="论述题(专业/分数)" min-width="150px" :show-overflow-tooltip="true">
                                    <template slot-scope="{ row }">
                                        <span>{{row.dcqCount + '/' + row.dcqScore}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="排序号" prop="orderNum" min-width="80px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="状态" min-width="100px" align="left">
                                    <template slot-scope="{row}">
                                        <el-tag :type="row.deleteStatus | colorFilter">
                                            {{ row.deleteStatus  | deleteStatusFilter}}
                                        </el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作人" prop="modifyByname" min-width="100px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="操作时间" prop="modifyTime" min-width="180px" :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column label="操作" align="center" width="170" fixed="right" class-name="small-padding fixed-width">
                                    <template slot-scope="{row}">
                                        <el-button v-if="row.deleteStatus===0" type="text" size="mini" icon="el-icon-edit" @click="toEdit(row.ruleId)">修改</el-button>
                                        <el-button v-if="row.deleteStatus===0" type="text" size="mini"  icon="el-icon-delete" @click="toDelete(row.ruleId, 1)">删除</el-button>
                                        <el-button v-if="row.deleteStatus===1" type="text" size="mini"  icon="el-icon-refresh-right" @click="toDelete(row.ruleId, 0)">恢复</el-button>
                                        <el-button v-if="row.deleteStatus===0" type="text" size="mini"  icon="el-icon-edit" @click="showPaper(row.ruleId)">试卷</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <!-- 分页组件 -->
                            <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                                        :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                                        @pagination="initTable"/>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
        <el-drawer
            title="试题管理"
            :visible.sync="editDrawer"
            :modal-append-to-body="true"
            :wrapperClosable="false"
            :destroy-on-close="true"
            direction="rtl"
            custom-class="demo-drawer"
            ref="editDrawer"
            size="80%"
        >
            <div class="demo-drawer__content  input-collapse">
                <el-form :model="info" :rules="ruleValidate" ref="editForm" label-width="100px" label-suffix=":">
                    <el-collapse v-model="activeNames">
                        <el-collapse-item title="基本信息" name="c1">
                            <template slot="title">
                                <span class="title">基本信息</span>
                            </template>
                            <el-row :gutter="32">
                                <el-col :span="8">
                                    <el-form-item label="试题名称" prop="ruleName">
                                        <el-input v-model="info.ruleName" placeholder="请输入名称"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="序号" prop="orderNum">
                                <span slot="label">
                                    序号：
                                    <el-tooltip  content="显示的顺序，不控制可输入0" effect="light" placement="top">
                                        <i class="el-icon-question" style="color:red"></i>
                                    </el-tooltip>
                                </span>
                                        <el-input v-model="info.orderNum"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row :gutter="32">
                                <el-col :span="16">
                                    <el-form-item label="工种分类" prop="categoryId">
                                        <GnvqSelect ref="gnvqSelect" :gnvqCatId.sync="info.gnvqCatId" :gnvqId.sync="info.gnvqId"
                                                    :levelId.sync="info.levelId"  @callback="levelChange"></GnvqSelect>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-collapse-item>
                        <el-collapse-item title="来源试题库" name="c2">
                            <template slot="title">
                                <span class="title">来源试题库</span>
                            </template>
                            <el-row :gutter="32">
                                <el-col :span="20" :offset="1">
                                    <el-table
                                        v-loading="tableLoading"
                                        :data="info.paperSources"
                                        fit
                                        stripe
                                        border
                                        highlight-current-row
                                        style="width: 100%;"
                                        :indent="20"
                                    >
                                        <el-table-column label="#" type="index" align="center"  width="50px"></el-table-column>
                                        <el-table-column label="类别" align="center">
                                            <template slot-scope="{row}">
                                                {{ row.examItemCat  | examItemCatFilter}}
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="工种" prop="gnvqName" align="left" :show-overflow-tooltip="true"></el-table-column>
                                        <el-table-column label="等级" prop="levelName" :show-overflow-tooltip="true"></el-table-column>
                                        <el-table-column label="基础题类型" prop="baseCatName" :show-overflow-tooltip="true"></el-table-column>
                                        <el-table-column label="试题组名" align="center">
                                            <template slot-scope="{row}">
                                                <span v-if="row.groupId==='' || row.groupId===null">全部分组</span>
                                                <span v-else-if="row.groupId===0">默认分组</span>
                                                <span v-else>{{ row.groupName}}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="操作" align="center" width="100" fixed="right" class-name="small-padding fixed-width">
                                            <template slot="header">
                                                <el-button size="mini" round type="success" @click="toAddExamStore">新 增</el-button>
                                            </template>
                                            <template slot-scope="scope">
                                                <el-button type="text" size="mini"  icon="el-icon-delete" @click="toDeletePaperSources(scope.$index, info.paperSources)">删除</el-button>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </el-col>
                            </el-row>
                        </el-collapse-item>
                        <el-collapse-item title="试题规则" name="c3">
                            <template slot="title">
                                <span class="title">试题规则</span>
                            </template>
                            <el-row :gutter="32">
                                <el-col :span="24">
                                    <el-form-item label="规则类型" prop="ruleType">
                                        <el-radio-group v-model="info.ruleType">
                                            <el-radio :label="0">默认规则</el-radio>
                                            <el-radio :label="1">知识点规则</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <template v-if="info.ruleType===0">
                                <el-divider content-position="left">基础题</el-divider>
                                <el-row :gutter="32">
                                    <el-col :span="6">
                                        <el-form-item label="基础题类别" prop="baseCatId">
                                            <el-select v-model="info.baseCatId">
                                                <el-option value="">请选择</el-option>
                                                <el-option v-for="item in baseCatList" :value="item.dictId" :label="item.dictName" :key="item.dictId"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item label="单选题数量" prop="baseScqCount">
                                            <el-input v-model="info.baseScqCount" placeholder="请输入单选题数量" @change="sumScore" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item label="判断题数量" prop="baseJcqCount">
                                            <el-input v-model="info.baseJcqCount" placeholder="请输入判断题数量" @change="sumScore" />
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-divider content-position="left">专业题</el-divider>
                                <el-row :gutter="32">
                                    <el-col :span="6">
                                        <el-form-item label="多选题数量" prop="mcqCount">
                                            <el-input v-model="info.mcqCount" placeholder="请输入多选题数量" @change="sumScore" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item label="单选题数量" prop="scqCount">
                                            <el-input v-model="info.scqCount" placeholder="请输入单选题数量" @change="sumScore" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item label="判断题数量" prop="jcqCount">
                                            <el-input v-model="info.jcqCount" placeholder="请输入判断题数量" @change="sumScore" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item label="论述题数量" prop="dcqCount">
                                            <el-input v-model="info.dcqCount" placeholder="请输入论述题数量" @change="sumScore" />
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-divider content-position="left">每道题分数</el-divider>
                                <el-row :gutter="32">
                                    <el-col :span="6">
                                        <el-form-item label="多选题" prop="mcqScore">
                                            <el-input v-model="info.mcqScore" placeholder="请输入多选题分数" @change="sumScore">
                                                <span slot="append">分</span>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item label="单选题" prop="scqScore">
                                            <el-input v-model="info.scqScore" placeholder="请输入单选题分数" @change="sumScore">
                                                <span slot="append">分</span>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item label="判断题" prop="jcqScore">
                                            <el-input v-model="info.jcqScore" placeholder="请输入判断题分数" @change="sumScore">
                                                <span slot="append">分</span>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item label="论述题" prop="dcqScore">
                                            <el-input v-model="info.dcqScore" placeholder="请输入论述题分数" @change="sumScore">
                                                <span slot="append">分</span>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="32">
                                    <el-col :span="6">
                                        <el-form-item label="总分数" prop="examScore">
                                            <el-input v-model="info.examScore" placeholder="请输入总分数" >
                                                <span slot="append">分</span>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item label="答题时长" prop="examTime">
                                            <el-input v-model="info.examTime" placeholder="请输入分钟" >
                                                <span slot="append">分钟</span>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </template>
                            <template v-if="info.ruleType===1">
                                <el-divider content-position="left">按照知识点设置试题规则</el-divider>
                                <el-row :gutter="32">
                                    <el-col :span="12" :offset="2">
                                        <el-button :disabled=" info.ruleId ? false : true" type="success" @click="toShowEditor">设置规则</el-button>
                                        <span>（首先保存当前规则基本信息后进行设置）</span>
                                    </el-col>
                                </el-row>
                            </template>
                        </el-collapse-item>
                        <el-collapse-item title="考试结果等级" name="c4">
                            <template slot="title">
                                <span class="title">考试结果等级</span>
                            </template>
                            <el-row :gutter="32">
                                <el-col :span="24">
                                    <el-form-item v-for="(item, index) in info.resultRules" :label="item.dictName" :key="index">
                                        <el-row :gutter="32">
                                            <el-col :span="4">
                                                <el-select v-model="item.minOperator">
                                                    <el-option value="gt" label="大于"></el-option>
                                                    <el-option value="ge" label="大于等于"></el-option>
                                                </el-select>
                                            </el-col>
                                            <el-col :span="6">
                                                <el-input v-model="item.minValue" placeholder="请输入最低分数" >
                                                    <span slot="append">分</span>
                                                </el-input>
                                            </el-col>
                                            <el-col :span="1">
                                                至
                                            </el-col>
                                            <el-col :span="4">
                                                <el-select v-model="item.maxOperator">
                                                    <el-option value="lt" label="小于"></el-option>
                                                    <el-option value="le" label="小于等于"></el-option>
                                                </el-select>
                                            </el-col>
                                            <el-col :span="6">
                                                <el-input v-model="item.maxValue" placeholder="请输入最高分数" >
                                                    <span slot="append">分</span>
                                                </el-input>
                                            </el-col>
                                        </el-row>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-collapse-item>
                        <el-collapse-item title="考试须知" name="c5">
                            <template slot="title">
                                <span class="title">考试须知</span>
                            </template>
                            <el-row :gutter="32">
                                <el-col :span="24">
                                    <el-form-item label="考试须知" prop="examIntro">
                                        <el-radio-group v-model="info.examIntro">
                                            <el-radio :label="0">无</el-radio>
                                            <el-radio :label="1">有</el-radio>
                                        </el-radio-group>
                                        <span style="margin-left: 50px; color: red">(目前仅对在线考试使用)</span>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" v-if="info.examIntro===1">
                                    <el-form-item label="考试须知内容" prop="examIntroDesc">
                                        <el-input v-model="info.examIntroDesc" type="textarea" :autosize="{ minRows: 5}"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-collapse-item>
                    </el-collapse>
                </el-form>
            </div>
            <div class="demo-drawer__footer">
                <el-button @click="editDrawer = false">取消</el-button>
                <el-button  type="primary" @click="saveData('editForm')">保存</el-button>
            </div>
        </el-drawer>
        <ExamStoreTypeSelect ref="examStoreTypeSelect" @callback="addPaperSource"></ExamStoreTypeSelect>
        <ExamPointRuleEditor ref="examPointRuleEditor" @callback="toEdit"></ExamPointRuleEditor>
        <ExamPaperDialog ref="examPaperDialog" ></ExamPaperDialog>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/Breadcrumb';
    import Pagination from '@/components/Pagination';
    import GnvqTree from "@v/components/GnvqTree";
    import GnvqSelect from "@v/components/GnvqSelect";
    import ExamStoreTypeSelect from "@v/course/components/ExamStoreTypeSelect";
    import ExamPointRuleEditor from './components/ExamPointRuleEditor'
    import ExamPaperDialog from './components/ExamPaperDialog'
    export default {
        components: {
            Pagination,
            Breadcrumb,
            GnvqTree,
            GnvqSelect,
            ExamStoreTypeSelect,
            ExamPointRuleEditor,
            ExamPaperDialog
        },
        filters: {
            deleteStatusFilter(status) {
                const statusMap = {
                    0: '正常',
                    1: '已删除',
                };
                return statusMap[status]
            },
            colorFilter(status) {
                const statusMap = {
                    0: 'danger',
                    1: 'success'
                };
                return statusMap[status]
            },
            examItemCatFilter(status) {
                const statusMap = {
                    1: '基础课',
                    2: '专业课',
                };
                return statusMap[status]
            },
        },
        data () {
            const validateNumber = (rule, value, callback) => {
                if (!Number.isInteger(+value)) {
                    callback(new Error('请输入数值'));
                } else {
                    callback();
                }
            };
            const validateNumber2 = (rule, value, callback) => {
                let regPos = /^\d+(\.\d+)?$/; // 非负浮点数
                if (!regPos.test(value)) {
                    callback(new Error('请输入数值'));
                } else {
                    callback();
                }
            };
            return {
                crumbData: [
                    {
                        path: '',
                        name: '首页'
                    },
                    {
                        path: '',
                        name: '试题'
                    }
                ],
                //列表查询数据
                queryParams: {
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    typeCode: '',
                    keyword: '',
                    deleteStatus: '0',
                    gnvqCatId: '',
                    gnvqId: '',
                    levelId: '',
                    examItemCat: '',
                    baseCatId: '',
                    orgId: this.appCookies.getCookieOID()
                },
                //列表数据
                tableData: [],
                tableLoading: false,
                //编辑、新增表单
                editDrawer: false,
                info: {
                    orderNum: 0,
                    paperSources: [],
                },
                activeNames: ["c1", "c2", "c3", "c4", "c5"],
                //表单验证规则
                ruleValidate: {
                    // 基本信息
                        ruleName: [
                        { required: true, message: '请输入试题名称', trigger: 'blur' }
                    ],
                        gnvqCatId: [
                            { required: true, type: 'number', message: '请选择工种类别', trigger: 'change' }
                        ],
                        gnvqId: [
                            { required: true, type: 'number', message: '请选择工种', trigger: 'change' }
                        ],
                        // levelId: [
                        //     { required: true, type: 'number', message: '请选择工种等级', trigger: 'change' }
                        // ],
                        baseCatId: [
                            { required: true, type: 'number', message: '请选择类别', trigger: 'blur' }
                        ],
                        baseScqCount: [
                        { required: true, validator: validateNumber, message: '请输入单选题数量', trigger: 'blur' }
                    ],
                        baseJcqCount: [
                        { required: true, validator: validateNumber, message: '请输入判断题数量', trigger: 'blur' }
                    ],
                        mcqCount: [
                        { required: true, validator: validateNumber, message: '请输入单选题数量', trigger: 'blur' }
                    ],
                        scqCount: [
                        { required: true, validator: validateNumber, message: '请输入单选题数量', trigger: 'blur' }
                    ],
                        jcqCount: [
                        { required: true, validator: validateNumber, message: '请输入判断题数量', trigger: 'blur' }
                    ],
                        dcqCount: [
                        { required: true, validator: validateNumber, message: '请输入论述题数量', trigger: 'blur' }
                    ],
                        mcqScore: [
                        { required: true, validator: validateNumber2, message: '请输入分数', trigger: 'blur' }
                    ],
                        scqScore: [
                        { required: true, validator: validateNumber2, message: '请输入分数', trigger: 'blur' }
                    ],
                        jcqScore: [
                        { required: true, validator: validateNumber2, message: '请输入分数', trigger: 'blur' }
                    ],
                        dcqScore: [
                        { required: true, validator: validateNumber2, message: '请输入分数', trigger: 'blur' }
                    ],
                        examScore: [
                        { required: true, validator: validateNumber2, message: '请输入总分数', trigger: 'blur' }
                    ],
                        examTime: [
                        { required: true, validator: validateNumber, message: '请输入答题时长', trigger: 'blur' }
                    ],
                        orderNum: [
                        { validator: validateNumber, trigger: 'blur' }
                    ]
                },
                baseCatList: []
            };
        },
        mounted () {
            this.getDictList();
            this.initTable();
        },
        computed: {
        },
        methods: {
            showPaper: function (ruleId) {
                this.$refs.examPaperDialog.open(ruleId)
            },
            toShowEditor: function () {
                this.$refs.examPointRuleEditor.open(this.info.ruleId)
            },
            getDictList: function () {
                this.apis.get('system/dict/getList?typeCode=0004')
                    .then((res) => {
                        if (res.code === 1) {
                            this.baseCatList = res.data || []
                        } else {
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    console.log(err)
                });
            },
            //获取列表
            initTable: function () {
                this.tableLoading = true;
                this.apis.postForm('course/examPaperRule/query', this.queryParams)
                    .then((res) => {
                        if (res.code === 1) {
                            this.tableData = res.data;
                            this.queryParams.totalRecord = res.count;
                            console.log(res);
                            this.tableLoading = false;
                        } else {
                            this.tableLoading = false;
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    this.tableLoading = false;
                    console.log(err)
                });
            },
            refreshTable: function () {
                this.queryParams.pageNumber = 0;
                this.initTable();
            },
            toQuery: function (data) {
                this.cellIndex = '-1';
                this.queryParams.gnvqCatId = '';
                this.queryParams.gnvqId = '';
                this.queryParams.levelId = '';
                if (data.type === 'CAT') {
                    this.queryParams.gnvqCatId = data.id;
                } else if (data.type === 'INFO') {
                    this.queryParams.gnvqCatId = data.categoryId;
                    this.queryParams.gnvqId = data.id;
                } else {
                    this.queryParams.gnvqCatId = data.categoryId;
                    this.queryParams.gnvqId = data.parentId;
                    this.queryParams.levelId = data.id;
                }
                this.refreshTable()
            },
            toLock: function (row) {
                //console.log(el-row)
                let data = {
                    ruleId: row.ruleId,
                    useStatus: row.useStatus,
                    operUserId: this.appCookies.getCookieUID(),
                    operUserName: this.appCookies.getCookieUNAME()
                };
                this.apis.postForm('course/examPaperRule/updUseStatus', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.$message({
                                message: '操作成功',
                                showClose: true, offset: 200, type: 'success'
                            });
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            toAdd: function () {
                this.info = {
                    ruleType: 0,
                    orderNum: 0,
                    gnvqCatId: this.queryParams.gnvqCatId,
                    gnvqId: this.queryParams.gnvqId,
                    levelId: this.queryParams.levelId,
                    baseScqCount: '',
                    baseJcqCount: '',
                    mcqCount: '',
                    scqCount: '',
                    jcqCount: '',
                    dcqCount: '',
                    mcqScore: '',
                    scqScore: '',
                    jcqScore: '',
                    dcqScore: '',
                    paperSources: [],
                    examIntro: 0
                };
                this.getResultRule()
                //console.log(this.info);
                this.editDrawer = true;
                // this.$refs.editForm.resetFields();
            },
            sumScore: function () {
                if (!this.info.baseScqCount) {
                    this.info.baseScqCount = 0;
                }
                if (!this.info.baseJcqCount) {
                    this.info.baseJcqCount = 0;
                }
                if (!this.info.mcqCount) {
                    this.info.mcqCount = 0;
                }
                if (!this.info.scqCount) {
                    this.info.scqCount = 0;
                }
                if (!this.info.jcqCount) {
                    this.info.jcqCount = 0;
                }
                if (!this.info.dcqCount) {
                    this.info.dcqCount = 0;
                }
                if (!this.info.mcqScore) {
                    this.info.mcqScore = 0;
                }
                if (!this.info.scqScore) {
                    this.info.scqScore = 0;
                }
                if (!this.info.jcqScore) {
                    this.info.jcqScore = 0;
                }
                if (!this.info.dcqScore) {
                    this.info.dcqScore = 0;
                }
                console.log(this.info)
                let examScore = parseInt(this.info.mcqCount, 0) * parseFloat(this.info.mcqScore) +
                    (parseInt(this.info.baseScqCount, 0) + parseInt(this.info.scqCount, 0)) * parseFloat(this.info.scqScore) +
                    (parseInt(this.info.baseJcqCount, 0) + parseInt(this.info.jcqCount, 0)) * parseFloat(this.info.jcqScore) +
                    parseInt(this.info.dcqCount, 0) * parseFloat(this.info.dcqScore);
                this.$set(this.info, 'examScore', examScore)
            },
            //gnvqSelect组件（工种等级分类）callback方法
            levelChange: function (levelInfo) {
                // console.log(levelInfo)
            },
            saveData: async function (formRef) {
                this.$refs[formRef].validate((valid) => {
                    console.log(this.$refs)
                    if (valid) {
                        let url = "course/examPaperRule/save";
                        this.info.orgId = this.appCookies.getCookieOID();
                        this.info.createBy = this.appCookies.getCookieUID();
                        this.info.createByname = this.appCookies.getCookieTNAME();
                        this.info.modifyBy = this.appCookies.getCookieUID();
                        this.info.modifyByname = this.appCookies.getCookieTNAME();
                        // 保存数据
                        this.apis.postJson(url, this.info)
                            .then((res) => {
                                if (res.code === 1) {
                                    this.editDrawer = false;
                                    this.initTable();
                                    this.$message({
                                        message: '保存成功',
                                        showClose: true, offset: 200, type: 'success'
                                    });
                                } else {
                                    this.$message({
                                        message: res.msg,
                                        showClose: true, offset: 50, type: 'error'
                                    });
                                }
                            }).catch(err => {
                            console.log(err);
                        });
                    }
                })
            },
            getResultRule: function () {
                this.apis.get('course/examPaperRule/getResultRule?orgId=' + this.appCookies.getCookieOID())
                    .then((res) => {
                        if (res.code === 1) {
                            this.$set(this.info, 'resultRules', res.data || [])
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            toEdit: function (keyId) {
                this.apis.get('course/examPaperRule/getById?ruleId=' + keyId)
                    .then((res) => {
                        console.log(res);
                        if (res.code === 1) {
                            this.info = res.data;
                            this.editDrawer = true;
                            this.$nextTick(function() {
                                // this.$refs.gnvqSelect.initData(this.info.gnvqCatId, this.info.gnvqId, this.info.levelId);
                            });
                        } else {
                            this.info = {}
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            toDelete: function (keyId, deleteStatus) {
                let title = '确定要删除此条数据？';
                if (deleteStatus === 0) {
                    title = '确定要恢复此条数据？';
                }
                this.$confirm(title, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let data = {
                        ruleId: keyId,
                        deleteStatus: deleteStatus,
                        operUserId: this.appCookies.getCookieUID(),
                        operUserName: this.appCookies.getCookieUNAME()
                    };
                    this.apis.postForm('course/examPaperRule/delete', data)
                        .then((res) => {
                            if (res.code === 1) {
                                this.initTable();
                                this.$message({
                                    message: '操作成功',
                                    showClose: true, offset: 200, type: 'success'
                                });
                            } else {
                                this.$message({
                                    message: res.msg,
                                    showClose: true, offset: 50, type: 'error'
                                });
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }).catch(() => {
                });
            },
            toAddExamStore: function () {
                this.$refs.examStoreTypeSelect.open(this.info.gnvqCatId, this.info.gnvqId, this.info.levelId);
            },
            toDeletePaperSources: function (index, rows) {
                this.$confirm('确定要删除？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    rows.splice(index, 1);
                }).catch(() => {
                });
            },
            addPaperSource: function (item) {
                if (!this.info.paperSources) {
                    this.info.paperSources = []
                }
                this.info.paperSources.push(item)
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>
