<template>
    <el-drawer
        title="试卷管理"
        :visible.sync="openDrawer"
        :modal-append-to-body="true"
        :wrapperClosable="false"
        :destroy-on-close="true"
        direction="rtl"
        custom-class="demo-drawer"
        ref="editDrawer"
        size="80%"
    >
        <div class="demo-drawer__content input-collapse">
            <el-row class="query-container">
                <el-col :span="24">
                    <el-form :inline="true">
                        <el-form-item label="">
                            <el-input v-model="queryParams.keyword" placeholder="查询关键字" style="width:100px"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-select v-model="queryParams.itemType" placeholder="请选择" style="width:100px">
                                <el-option label="全部" value=""></el-option>
                                <el-option label="多选题" value="1"></el-option>
                                <el-option label="单选题" value="2"></el-option>
                                <el-option label="判断题" value="3"></el-option>
                                <el-option label="论述题" value="4"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-select v-model="queryParams.deleteStatus" placeholder="请选择状态" style="width:100px">
                                <el-option label="全部" value=""></el-option>
                                <el-option label="正常" :value="0"></el-option>
                                <el-option label="已删除" :value="1"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                        </el-form-item>
                        <el-button-group style="float: right">
                            <el-button class="filter-item" type="success" round icon="el-icon-edit" @click="toAdd">新增</el-button>
                            <el-button class="filter-item" type="success" round icon="el-icon-add" @click="toImport">导入</el-button>
                        </el-button-group>
                        <div style="float: right;">
                            <el-form-item label="合计总分：">
                                {{totalScore}}分
                                <a  href="../../SHITIImportModelGaoPin.xlsx" target="_blank" style="margin: 0 10px;">下载导入模板</a>
                            </el-form-item>
                        </div>
                    </el-form>
                </el-col>
            </el-row>
            <el-row :gutter="32" class="table-container">
                <el-col>
                    <el-table
                        v-loading="tableLoading"
                        :data="tableData"
                        fit
                        highlight-current-row
                        style="width: 100%;"
                        height="calc(100vh - 300px)"
                        ref="selectTable"
                        :indent="20"
                    >
                        <el-table-column
                            type="selection"
                            width="55">
                        </el-table-column>
                        <el-table-column label="试题名称" prop="itemName" min-width="120px" align="left"></el-table-column>
                        <el-table-column label="试题类型" width="120px" align="center">
                            <template slot-scope="{row}">
                                <el-tag :type="row.itemType | examItemTypeColorFilter">
                                    {{ row.itemType  | examItemTypeFilter}}
                                </el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column label="分数" prop="itemScore" width="60" align="center"></el-table-column>
                        <el-table-column label="序号" prop="orderNum" width="60" align="center"></el-table-column>
                        <el-table-column label="状态" min-width="100px" align="center">
                            <template slot-scope="{row}">
                                <el-tag :type="row.deleteStatus | colorFilter">
                                    {{ row.deleteStatus  | deleteStatusFilter}}
                                </el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column label="创建时间" width="140px" align="center">
                            <template slot-scope="{row}">
                                <span>{{ row.modifyTime | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" align="center" width="120" fixed="right" class-name="small-padding fixed-width">
                            <template slot-scope="{row}">
                                <el-button v-if="row.deleteStatus===0" type="text" size="mini" icon="el-icon-edit" @click="toEdit(row.itemId)">修改</el-button>
                                <el-button v-if="row.deleteStatus===0" type="text" size="mini"  icon="el-icon-delete" @click="toDelete(row.itemId, 1)">删除</el-button>
                                <el-button v-if="row.deleteStatus===1" type="text" size="mini"  icon="el-icon-refresh-right" @click="toDelete(row.itemId, 0)">恢复</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                                :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                                :first-zero="0"
                                @pagination="initTable"/>
                </el-col>
            </el-row>
        </div>
        <div class="demo-drawer__footer">
            <el-button @click="openDrawer = false">取消</el-button>
        </div>
        <el-dialog
            title="导入"
            :visible.sync="importDialog"
            :destroy-on-close="true"
            :append-to-body="true"
            :close-on-click-modal="false"
            width="700px">
            <el-form ref="editForm" label-width="140px" label-suffix=":">
                <el-row>
                    <el-col>
                        <el-form-item label="上传文件">
                            <el-upload
                                class="upload-demo"
                                accept=".xls,.xlsx"
                                ref="upload"
                                :action="getUploadUrl()"
                                :limit="1"
                                :before-upload="beforeUpload"
                                :on-change="fileChange"
                                :file-list="fileList"
                                :auto-upload="false">
                                <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                                <div slot="tip" class="el-upload__tip">只能上传.xls,.xlsx文件</div>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="importDialog = false">关 闭</el-button>
                <el-button type="primary" @click="addImport('editForm')">确认导入</el-button>
            </span>
        </el-dialog>
        <HighfreqPaperEditDrawer ref="highfreqPaperEditDrawer" @callback="refreshTable"></HighfreqPaperEditDrawer>
    </el-drawer>
</template>

<script>
import HighfreqPaperEditDrawer from "@v/course/components/HighfreqPaperEditDrawer.vue";
import Pagination from "@c/Pagination/index.vue";

export default {
    components: {
        Pagination,
        HighfreqPaperEditDrawer
    },
    filters: {
        examItemTypeFilter(status) {
            const statusMap = {
                1: '多选题',
                2: '单选题',
                3: '判断题',
                4: '论述题',
            };
            return statusMap[status]
        },
        examItemTypeColorFilter(status) {
            const statusMap = {
                1: '',
                2: 'success',
                3: 'warning',
                4: 'info',
            };
            return statusMap[status]
        },
        deleteStatusFilter(status) {
            const statusMap = {
                0: '正常',
                1: '已删除',
            };
            return statusMap[status]
        },
        colorFilter(status) {
            const statusMap = {
                0: 'success',
                1: 'danger',
            };
            return statusMap[status]
        },
    },
    data () {
        return {
            openDrawer: false,
            queryParams: {
                pageNumber: 1,
                pageSize: 10,
                totalRecord: 0,
                paperId: '',
                itemType: '',
                keyword: '',
                deleteStatus: 0,
                dataStatus: '',
                orgId: this.appCookies.getCookieOID(),
            },
            info: {},
            totalScore: 0,
            tableData: [],
            tableLoading: false,
            importDialog: false,
            file: null,
            fileList: [],
        };
    },
    mounted () {
    },
    methods: {
        open: function (paperId) {
            this.queryParams.paperId = paperId
            this.refreshTable()
            this.openDrawer = true;
        },
        initTable: function () {
            this.tableLoading = true;
            this.apis.postForm('course/high/paper/item/query', this.queryParams)
                .then((res) => {
                    if (res.code === 1) {
                        this.tableData = res.data;
                        this.queryParams.totalRecord = res.count;
                        this.tableLoading = false;
                    } else {
                        this.tableLoading = false;
                        console.log('发生错误:' + res.msg);
                    }
                }).catch((err) => {
                this.tableLoading = false;
                console.log(err)
            });
        },
        getTotalScore: function () {
            this.apis.get('course/high/paper/getTotalScore?paperId=' + this.queryParams.paperId)
                .then((res) => {
                    if (res.code === 1) {
                        this.totalScore = res.data;
                    }
                }).catch((err) => {
                console.log(err)
            });
        },
        refreshTable: function () {
            this.queryParams.pageNumber = 1;
            this.initTable();
            this.getTotalScore();
        },
        toAdd: function () {
            this.$refs.highfreqPaperEditDrawer.open(this.queryParams.paperId, '')
        },
        toEdit: function (keyId) {
            this.$refs.highfreqPaperEditDrawer.open(this.queryParams.paperId, keyId)
        },
        toDelete: function (id, deleteStatus) {
            let title = '确定要删除此条数据？';
            if (deleteStatus === 0) {
                title = '确定要恢复此条数据？';
            }
            this.$confirm(title, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    itemId: id,
                    deleteStatus: deleteStatus,
                    operUserId: this.appCookies.getCookieUID(),
                    operUserName: this.appCookies.getCookieUNAME()
                };
                this.apis.postForm('course/high/paper/item/delete', data)
                    .then((res) => {
                        if (res.code === 1) {
                            this.initTable();
                            this.$message({
                                message: '操作成功',
                                showClose: true, offset: 200, type: 'success'
                            });
                        } else {
                            this.$message({
                                message: res.msg,
                                showClose: true, offset: 50, type: 'error'
                            });
                        }
                    }).catch(err => {
                    console.log(err);
                });
            }).catch(() => {
            });
        },
        toImport: function () {
            this.file = null;
            this.info = {}
            this.importDialog = true;
        },
        //上传文件
        beforeUpload(file) {
            //文件类型
            const isType = file.type === 'application/vnd.ms-excel'
            const isTypeComputer = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            const fileType = isType || isTypeComputer
            if (!fileType) {
                this.$message.error('上传文件只能是xls/xlsx格式！')
            }
            this.file = file;
            return fileType;
        },
        getUploadUrl: function () {
            return process.env.VUE_APP_API_HOST + 'system/file/uploadFile';
        },
        fileChange(file, fileList) {
            console.log(file)
            this.file = file.raw
        },
        //确定导入
        addImport: async function () {
            if (this.file==null) {
                this.$message({
                    message: '请选择文件',
                    showClose: true, offset: 50, type: 'error'
                });
                return
            }
            let formData = new FormData();
            formData.append('file', this.file);

            let url = "course/high/paper/item/import";
            this.info.paperId = this.queryParams.paperId;
            this.info.createBy = this.appCookies.getCookieUID();
            this.info.createByname = this.appCookies.getCookieUNAME() ;
            this.info.modifyBy = this.appCookies.getCookieUID();
            this.info.modifyByname = this.appCookies.getCookieUNAME() ;

            formData.append("info", new Blob([JSON.stringify(this.info)], { type: "application/json" }));
            // 保存数据
            this.apis.postFile(url, formData)
                .then((res) => {
                    if (res.code === 1) {
                        this.file = null
                        this.importDialog = false;
                        this.$message({
                            message: '导入成功，共导入',
                            showClose: true, offset: 200, type: 'success'
                        });
                        this.refreshTable();
                    } else {
                        this.$message({
                            message: res.msg,
                            showClose: true, offset: 50, type: 'error'
                        });
                    }
                }).catch(err => {
                console.log(err);
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.item-box {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    border: dotted 1px #0e9a00;
    .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px 10px;
    }
}
</style>
